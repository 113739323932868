import axios from 'axios';

export const AddTracking = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/trackings', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetOrderTrackings = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/trackings/order/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}
