import React from 'react';
import { Layout } from 'antd';
import PageLayoutHeader from './PageLayoutHeader';
import PageLayoutFooter from './PageLayoutFooter';
import { NotificationContainer } from 'react-notifications';
import LoadingOverlay from 'react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader';

export default function PageLayout({children, loading}) {
    const { Content } = Layout;
    return (
        <LoadingOverlay active={loading} spinner={<PulseLoader color="#ff5cf4" size={15} margin={2} />}>
            <Layout>
                <PageLayoutHeader />
                <Content>{children}</Content>
                <PageLayoutFooter />
                <NotificationContainer />
            </Layout>
        </LoadingOverlay>
    )
}
