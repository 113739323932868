import React from 'react';
import { Drawer, Button, Grid, Input, Form, Alert } from 'antd';
import { UserVerify } from '../../services/Users';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';

import { useDispatch, useSelector } from 'react-redux';
import { setAuthDetails } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightVerify({isVisible, setDrawerRightVerify, setDrawerRightAccount}) {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.reducer.userDetails);
    const handleSetAuthDetails = (userDetails) => dispatch(setAuthDetails(userDetails));

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleForm = async(values) => {
        const postData = {
            email_code: values.email_code,
            mobile_code: values.mobile_code
        }
        const result = await UserVerify(postData, userDetails.user_uuid);
        if (result.data.status === 'success') {
            handleSetAuthDetails({
                ...userDetails,
                is_verified_email: 1,
                is_verified_mobile: 1
            })
            Debounce(NotificationManager.success('Your account is now fully verified.', 'Notice', 3000), 3000);
            handleClose();
        } else {
            Debounce(NotificationManager.error('Codes are incorrect. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleClose = () => {
        setDrawerRightVerify(false);
        setDrawerRightAccount(true);
    }

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title={'Verify Account'} placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose()} visible={isVisible}>
            <Alert
                className="bm-alert mb-4"
                message="Notice"
                description="We sent you an email and a text message to your phone with the verification codes. Please enter them in the fields below."
                type="info"
            />
            <Form layout="vertical" form={form} onFinish={handleForm}>
                <Form.Item label="Email Code" name="email_code" rules={[{ required: true, message: 'Please enter code from email address.' }]}>
                    <Input placeholder="email code" className="bm-input" defaultValue={''} />
                </Form.Item>
                <Form.Item label="Mobile Number Code" name="mobile_code" rules={[{ required: true, message: 'Please enter code we sent to your mobile number.' }]}>
                    <Input placeholder="mobile number code" className="bm-input" defaultValue={''} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="btn btn-primary mt-4" size="large" htmlType="submit" block>Confirm & Verify</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}