import {
    AUTH_LOGIN,
    USER_ADDRESSES,
    SET_CURRENT_LOCATION,
    SET_SERVICE_AREAS,
    SET_NEAR_AREA,
    SET_AREA_BRANCHES,
    SET_AREA_ITEMS,
    SET_AREA_VOUCHERS,
    SET_SELECTED_BRANCH,
    SET_SELECTED_MERCHANT,
    SET_ACTIVE_BRANCH,
    ADD_BAG_ITEM,
    REMOVE_BAG_ITEM,
    EMPTY_BAG_ITEMS,
    UPDATE_BAG_ITEM,
    SET_SELECTED_CATEGORY,
    SET_SELECTED_VOUCHER,
    SET_SELECTED_VOUCHER_ITEM,
    SET_PENDING_ORDERS,
    SET_PAST_ORDERS,
} from './Types';

export const setAuthDetails = (userDetails) => ({
    type: AUTH_LOGIN,
    data: userDetails
});

export const setUserAddresses = (addresses) => ({
    type: USER_ADDRESSES,
    data: addresses
});

export const setCurrentLocation = (currentLocation) => ({
    type: SET_CURRENT_LOCATION,
    data: currentLocation
});

export const setServiceAreas = (serviceAreas) => ({
    type: SET_SERVICE_AREAS,
    data: serviceAreas
});

export const setNearServiceArea = (serviceArea) => ({
    type: SET_NEAR_AREA,
    data: serviceArea
});

export const setAreaBranches = (branches) => ({
    type: SET_AREA_BRANCHES,
    data: branches
});

export const setAreaItems = (items) => ({
    type: SET_AREA_ITEMS,
    data: items
});

export const setAreaVouchers = (vouchers) => ({
    type: SET_AREA_VOUCHERS,
    data: vouchers
});

export const setSelectedBranch = (branch) => ({
    type: SET_SELECTED_BRANCH,
    data: branch
});

export const setSelectedMerchant = (merchant) => ({
    type: SET_SELECTED_MERCHANT,
    data: merchant
});

export const setActiveBranch = (branch) => ({
    type: SET_ACTIVE_BRANCH,
    data: branch
});

export const addBagItem = (item) => ({
    type: ADD_BAG_ITEM,
    data: item
});

export const removeBagItem = (key) => ({
    type: REMOVE_BAG_ITEM,
    data: key
});

export const updateBagItem = (values) => ({
    type: UPDATE_BAG_ITEM,
    data: values
});

export const emptyBagItems = (data) => ({
    type: EMPTY_BAG_ITEMS,
    data: data
});

export const setSelectedCategory = (category) => ({
    type: SET_SELECTED_CATEGORY,
    data: category
});

export const setSelectedVoucher = (voucher) => ({
    type: SET_SELECTED_VOUCHER,
    data: voucher
});

export const setSelectedVoucherItem = (item) => ({
    type: SET_SELECTED_VOUCHER_ITEM,
    data: item
});

export const setPendingOrders = (orders) => ({
    type: SET_PENDING_ORDERS,
    data: orders
});

export const setPastOrders = (orders) => ({
    type: SET_PAST_ORDERS,
    data: orders
});