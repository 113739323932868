import axios from 'axios';

export const GetTopBranches = async(limit) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/top/' + limit);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetAreaTopBranches = async(uuid, limit) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/top/' + limit + '/service-area/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetAreaRecentBranches = async(uuid, limit) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/recent/' + limit + '/service-area/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const SearchBranches = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/search', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const SearchString = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/search-string', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetBranchDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetServiceAreaBranches = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchant-branches/service-area/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}