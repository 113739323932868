import React, { useState, useEffect } from 'react';
import { Drawer, Alert, Button, Grid, Input, Form, Select } from 'antd';
import { FilterObject, Capitalize } from '../../functions/Utilities';
import { AddAddress } from '../../services/Addresses';
import { GetUserAddresses } from '../../services/Addresses';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import PHAddressesJSON from '../../data/PHAddress2019.json';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { ConfigKeys } from '../../config/ConfigKeys';
import BMMarker from '../../assets/img/bm-marker-scaled.png';

import { useSelector, useDispatch } from 'react-redux';
import { setUserAddresses } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightAddressAdd({isVisible, setDrawerRightAddressAdd, setDrawerRightAddresses}) {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.reducer.userDetails);
    const currentLocation = useSelector(state => state.reducer.currentLocation);
    const handleSetUserAddresses = (addresses) => dispatch(setUserAddresses(addresses));

    const { Option } = Select;
    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: ConfigKeys.GoogleServices.key
    });

    const [regionsData, setRegionsData] = useState([]);
    const [provincesData, setProvincesData] = useState([]);
    const [munData, setMunData] = useState([]);
    const [brgyData, setBrgyData] = useState([]);
    const [markerPosition, setMarkerPosition] = useState(currentLocation === null ? {lat: 11.6736457, lng: 118.1286001} : {lat: currentLocation.lat, lng: currentLocation.lng});

    const handleRegionsData = () => {
        let regionsDataFormatted = [];
        for (const key in PHAddressesJSON) {
            regionsDataFormatted.push({
                region_name: PHAddressesJSON[key].region_name,
                province_list: PHAddressesJSON[key].province_list
            });
        }
        setRegionsData(regionsDataFormatted);
        setProvincesData([]);
        setMunData([]);
        setBrgyData([]);
    }

    const handleChangeRegion = (value) => {
        setProvincesData([]);
        let provincesDataFormatted = []
        const provincesDataResult = FilterObject(regionsData, value, 'region_name');
        if (provincesDataResult[0]) {
            for (const key in provincesDataResult[0].province_list) {
                provincesDataFormatted.push({
                    province_name: key,
                    municipality_list: provincesDataResult[0].province_list[key].municipality_list
                })
            }
        }
        setProvincesData(provincesDataFormatted);
        setMunData([]);
        setBrgyData([]);
        form.setFieldsValue({
            prov_region: null,
            city_mun: null,
            barangay: null
        });
    }

    const handleChangeProvince = (value) => {
        setMunData([]);
        const munDataResult = FilterObject(provincesData, value, 'province_name');
        let munDataFormatted = [];
        if (munDataResult[0]) {
            for (const key in munDataResult[0].municipality_list) {
                munDataFormatted.push({
                    city_mun_name: key,
                    barangay_list: munDataResult[0].municipality_list[key].barangay_list
                })
            }
        }
        setMunData(munDataFormatted);
        setBrgyData([]);
        form.setFieldsValue({
            city_mun: null,
            barangay: null
        });
    }

    const handleChangeMun = (value) => {
        setBrgyData([]);
        const munBrgyResult = FilterObject(munData, value, 'city_mun_name');
        let brgyDataFormatted = [];
        if (munBrgyResult[0]) {
            munBrgyResult[0].barangay_list.forEach(item => {
                brgyDataFormatted.push({
                    brgy_name: item
                })
            })
        }
        setBrgyData(brgyDataFormatted);
        form.setFieldsValue({
            barangay: null
        });
    }

    const handleMarkerPosition = (e) => {
        setMarkerPosition({lat: e.latLng.lat(), lng: e.latLng.lng()});
    }

    const handleForm = async(values) => {
        const postData = {
            user_uuid: userDetails.user_uuid,
            street: Capitalize(values.street + ' ' + values.barangay),
            city_mun: Capitalize(values.city_mun),
            prov_region: Capitalize(values.prov_region + ' ' + values.region),
            zip_code: values.zip_code,
            map_lat: markerPosition.lat,
            map_lng: markerPosition.lng,
            address_status: 1
        }
        const result = await AddAddress(postData);
        if (result.data.status === 'success') {
            handleGetUserAddresses();
            Debounce(NotificationManager.success('The address has been added to your profile.', 'Notice', 1000), 1000);
        } else {
            Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleGetUserAddresses = async() => {
        const userAddressesResult = await GetUserAddresses(userDetails.user_uuid);
        handleSetUserAddresses(userAddressesResult.data.data);
    }

    const handleCloseAdd = () => {
        setDrawerRightAddressAdd(false);
        setDrawerRightAddresses(true);
    }

    useEffect(() => {
        handleRegionsData();
    }, []);

    return ( 
        <Drawer className="bm-drawer" title="Add Address" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleCloseAdd(e)} visible={isVisible}>
            <Form layout="vertical" form={form} onFinish={handleForm}>
                <Form.Item label="Region" name="region" rules={[{ required: true, message: 'Please select region.' }]}>
                    <Select className="bm-select" placeholder="select region" onChange={(value) => handleChangeRegion(value)}>
                        {
                            regionsData &&
                            regionsData.map(item => {
                                return <Option value={item.region_name}>{item.region_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Province" name="prov_region" rules={[{ required: true, message: 'Please select province.' }]}>
                    <Select className="bm-select" placeholder="select prov" onChange={(value) => handleChangeProvince(value)}>
                        {
                            provincesData &&
                            provincesData.map(item => {
                                return <Option value={item.province_name}>{item.province_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="City / Municipality" name="city_mun" rules={[{ required: true, message: 'Please select city or municipality.' }]}>
                    <Select className="bm-select" placeholder="select city / municipality" onChange={(value) => handleChangeMun(value)}>
                        {
                            munData &&
                            munData.map(item => {
                                return <Option value={item.city_mun_name}>{item.city_mun_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Barangay" name="barangay">
                    <Select className="bm-select" placeholder="select barangay" rules={[{ required: true, message: 'Please select barangay.' }]}>
                        {
                            brgyData &&
                            brgyData.map(item => {
                                return <Option value={item.brgy_name}>{item.brgy_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="House No. / Steet" name="street" rules={[{ required: true, message: 'Please enter your house number and street.' }]}>
                    <Input placeholder="house no. / street" className="bm-input" />
                </Form.Item>
                <Form.Item label="ZIP Code" name="zip_code" rules={[{ required: true, message: 'Please enter your ZIP code.' }]}>
                    <Input placeholder="zip code" className="bm-input" />
                </Form.Item>
                {
                    isLoaded &&
                    <div>
                        <Alert
                            className="bm-alert mt-4 mb-4"
                            message="Notice"
                            description="Drag the marker to your excact address location. This will help our delivery partners deliver your food items fast. You can expand, zoom in, and zoom out to help you locate your address faster."
                            type="info"
                        />
                        <GoogleMap
                            id='map'
                            mapContainerStyle={{ width: '100%', height: '400px', borderRadius: '.5rem'}}
                            center={markerPosition}
                            zoom={15}>
                            <Marker
                                icon={{
                                    url: BMMarker
                                }}
                                draggable={true}
                                onDragEnd={(e) => handleMarkerPosition(e)}
                                position={markerPosition}/>
                        </GoogleMap>
                    </div>
                }
                <Form.Item>
                    <Button type="primary" className="btn btn-primary mt-4" size="large" htmlType="submit" block>Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}