import React from 'react';
import { Image } from 'antd';
import BMLogoDark from '../../assets/img/bilimoko-logo-dark.png';
import { useHistory } from 'react-router-dom';

export default function BMBranding({dimensions}) {
    const history = useHistory();
    return (
        <Image src={BMLogoDark} preview={false} title={'bilimoko'} width={dimensions.width} style={{cursor: 'pointer'}} onClick={() => history.push('/')} />
    )
}