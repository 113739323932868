import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Button, List } from 'antd';
import PageLayout from '../../components/layout/PageLayout';
import SectionWrapper from '../../components/layout/SectionWrapper';
import { FiArrowRightCircle } from 'react-icons/fi';
import { ConvertToSlug } from '../../functions/Utilities';
import { FoodCategoryTypes } from '../../data/FoodCategoryTypes';
import { IconContext } from 'react-icons';
import ReactGA from 'react-ga';

export default function Categories() {
    const history = useHistory();

    const IconCircleRight = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons', color: '#0023c4', size: '22px' }}>
                <FiArrowRightCircle />
            </IconContext.Provider>
        )
    }

    const ListItem = (item) => {
        return (
            <Col>
                <Button className="btn btn-custom btn-border" size={'large'} block onClick={(e) => history.push('/categories/' + item.code + '/' + ConvertToSlug(item.categoryName))}>
                    <span>{item.categoryName}</span>
                    <IconCircleRight />
                </Button>
            </Col>
        )
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);
    
    return (
        <PageLayout>
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">Food Categories</h1>
                    <h2 className="section-subtitle">Select one that you have been craving for.</h2>
                </div>
                <List
                    className="mt-5"
                    dataSource={FoodCategoryTypes}
                    renderItem={(item) => ListItem(item)}
                    grid={{
                        gutter: [36, 24],
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 4,
                        xxl: 4
                    }}
                />
            </SectionWrapper>
        </PageLayout>
    )
}