import React from 'react';
import { Drawer, Button, Grid, Row, Col, Input, Image, Form } from 'antd';
import BMBranding from '../../components/elements/BMBranding';
import { ForgotPassword } from '../../services/Users';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import AppStoreAndroid from '../../assets/img/app-store-android.png';
import AppStoreApple from '../../assets/img/app-store-apple.png';
import parsePhoneNumber from 'libphonenumber-js';
import { SendMail } from '../../services/Mail';

const { useBreakpoint } = Grid;

export default function DrawerRightForgot({isVisible, setDrawerRightForgot, setDrawerRightLogin}) {
    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleOnSubmit = async(values) => {
        const mobileNumber = parsePhoneNumber(values.mobile_number, 'PH');
        const postData = {
            email_address: values.email_address,
            mobile_number: mobileNumber.number.replace('+', ''),
        }
        const forgotResult = await ForgotPassword(postData);
        if (forgotResult.data.status === 'success') {
            const passwordChange = {
                'toEmail': forgotResult.data.data.email_address,
                'firstName': forgotResult.data.data.first_name,
                'userUuid': forgotResult.data.data.user_uuid
            }
            Promise.all([
                SendMail('forgot-password', passwordChange)
            ]).then(result => {
                Debounce(NotificationManager.success('We sent an email with the instructions on how to reset your password.', 'Sorry', 3000), 3000);
            })
            handleClose();
        } else {
            Debounce(NotificationManager.error('Email and/or password is incorrect. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleClose = () => {
        setDrawerRightForgot(false);
        setDrawerRightLogin(true);
    }

    return (
        <Drawer className="bm-drawer" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose()} visible={isVisible}>
            <div className="py-5">
                <Row className="brand-container auth" justify="center">
                    <Col xs={24} md={20} className="text-center">
                        <BMBranding dimensions={{width: 120, marginRight: 120}} />
                        <h1 className="font-weight-900 font-color-grey-base-x5 mt-4">Forgot Password</h1>
                        <Form
                            className="mt-4"
                            layout="vertical"
                            form={form}
                            onFinish={handleOnSubmit}>
                            <Form.Item name={'email_address'} label="Email Address" type={'email'} rules={[{ required: true, message: 'Email address is required.' }]}>
                                <Input placeholder="email address" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'mobile_number'} label="Mobile Number" type={'text'} rules={[{ required: true, message: 'Mobile number is required.' }]}>
                                <Input placeholder="mobile number" className="bm-input" />
                            </Form.Item>
                            <Button className="btn btn-primary mt-2" size={'large'} block htmlType={'submit'}>Submit</Button>
                        </Form>
                        <div className="my-4">
                            <Button type="link" onClick={(e) => handleClose()} className="link link-primary" block>Back to Login</Button>
                        </div>
                    </Col>
                </Row>
                <div className="mt-4 text-center">
                    <h3>Also available in</h3>
                    <Row gutter={[16, 16]} wrap={false}>
                        <Col span={12} className="text-end">
                            <a href={'https://bit.ly/373QNQ1'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreAndroid} preview={false} width={140} /></a>
                        </Col>
                        <Col span={12} className="text-start">
                            <a href={'https://apple.co/3BANT3p'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreApple} preview={false} width={140} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Drawer>
    )
}