import React from 'react';
import { Drawer, Button, Grid, Row, Col, Card, Badge } from 'antd';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IconContext } from 'react-icons';

import { useSelector, useDispatch } from 'react-redux';
import { setAuthDetails, setSelectedVoucher, setSelectedVoucherItem, setPendingOrders, setPastOrders } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightAccount({isVisible, setDrawerRightAccount, setDrawerRightOrders, setDrawerRightAddresses, setDrawerRightProfileView, setDrawerRightVerify}) {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.reducer.userDetails);
    const pendingOrders = useSelector(state => state.reducer.pendingOrders);
    const handleSetAuthDetails = (userDetails) => dispatch(setAuthDetails(userDetails));
    const handleSetSelectedVoucher = (voucher) => dispatch(setSelectedVoucher(voucher));
    const handleSetSelectedVoucherItem = (item) => dispatch(setSelectedVoucherItem(item));
    const handleSetPendingOrders = (orders) => dispatch(setPendingOrders(orders));
    const handleSetPastOrders = (orders) => dispatch(setPastOrders(orders));
    const screens = useBreakpoint();

    const handleLogoutUser = () => {
        handleSetAuthDetails(null);
        handleSetSelectedVoucher(null);
        handleSetSelectedVoucherItem(null);
        handleSetPendingOrders(null);
        handleSetPastOrders(null);
        setDrawerRightAccount(false);
    }

    const IconCircleRight = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons', color: '#0023c4', size: '22px' }}>
                <FiArrowRightCircle />
            </IconContext.Provider>
        )
    }

    const handleOpenOrders = (event) => {
        setDrawerRightAccount(false);
        setDrawerRightOrders(true);
    }

    const handleOpenAddresses = (event) => {
        setDrawerRightAccount(false);
        setDrawerRightAddresses(true);
    }

    const handleProfileView = (event) => {
        setDrawerRightAccount(false);
        setDrawerRightProfileView(true);
    }

    const handleVerify = (event) => {
        setDrawerRightAccount(false);
        setDrawerRightVerify(true);
    }

    return (
        <Drawer className="bm-drawer" title="Account" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => setDrawerRightAccount(false)} visible={isVisible}>
            {
                userDetails !== null &&
                <div>
                    <Card className="bm-card pointer mb-3" onClick={(e) => handleProfileView(e)}>
                        <Row gutter={[16, 16]} wrap={false}>
                            <Col flex="auto">
                                <h3 className="mb-2">{`${userDetails.first_name} ${userDetails.last_name}`}</h3>
                                <h5 className="mb-0 font-color-grey-base-x2">EMAIL ADDRESS</h5>
                                <div className="mb-2">{userDetails.email_address}</div>
                                <h5 className="mb-0 font-color-grey-base-x2">MOBILE NUMBER</h5>
                                <div>{userDetails.mobile_number}</div>
                            </Col>
                            <Col flex="60px" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconCircleRight />
                            </Col>
                        </Row>
                    </Card>
                    {
                        pendingOrders &&
                        <Badge count={pendingOrders.length} className="bm-badge-secondary w-100">
                            <Button className="btn btn-custom btn-border mb-3" size={'large'} block onClick={(e) => handleOpenOrders()}>
                                <span>Orders</span>
                                <IconCircleRight />
                            </Button>
                        </Badge>
                    }
                    {
                        userDetails && (userDetails.is_verified_email !== 1 || userDetails.is_verified_mobile !== 1) &&
                        <Button className="btn btn-custom btn-border mb-3" size={'large'} block onClick={(e) => handleVerify()}>
                            <span>Protect and Verify</span>
                            <IconCircleRight />
                        </Button>
                    }
                    
                    <Button className="btn btn-custom btn-border mb-3" size={'large'} block onClick={(e) => handleOpenAddresses()}>
                        <span>Addresses</span>
                        <IconCircleRight />
                    </Button>
                    <Button className="btn btn-custom btn-border mb-3" size={'large'} block onClick={(e) => window.open('https://bilimoko.net/web/support')}>
                        <span>Support</span>
                        <IconCircleRight />
                    </Button>
                    <Button className="btn btn-custom btn-border mb-3" size={'large'} block onClick={(e) => window.open('https://bilimoko.net/web/privacy-policy')}>
                        <span>Privacy Policy</span>
                        <IconCircleRight />
                    </Button>
                    <Button className="btn btn-custom btn-border mb-5" size={'large'} block onClick={(e) => window.open('https://bilimoko.net/web/terms-and-conditions')}>
                        <span>Terms & Conditions</span>
                        <IconCircleRight />
                    </Button>
                </div>
            }
            <Button className="btn btn-danger" size={'large'} block onClick={handleLogoutUser}>Logout</Button>
            <div className="mt-5 font-color-primary-x5">BILIMOKO Web App</div>
            <div className="font-color-grey-base-x4">Ver {process.env.REACT_APP_VERSION}</div>
        </Drawer>
    )
}