import axios from 'axios';

export const GetUserAddresses = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/addresses/user/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddAddress = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/addresses', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateAddress = async(values, uuid) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/addresses/' + uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetAddressDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/addresses/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}