import React from 'react';

export default function Motorcycle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="34" viewBox="0 0 43 34">
            <g>
                <path d="M11.5,34A7.5,7.5,0,1,1,19,26.5,7.509,7.509,0,0,1,11.5,34Zm0-12A4.5,4.5,0,1,0,16,26.5,4.5,4.5,0,0,0,11.5,22Z" fill="#3e4166"/>
                <path d="M35.5,34A7.5,7.5,0,1,1,43,26.5,7.509,7.509,0,0,1,35.5,34Zm0-12A4.5,4.5,0,1,0,40,26.5,4.5,4.5,0,0,0,35.5,22Z" fill="#3e4166"/>
                <path d="M29.5,28h-12a1.5,1.5,0,0,1,0-3h12a1.5,1.5,0,0,1,0,3Z" fill="#3e4166"/>
                <path d="M5.5,28h-4a1.5,1.5,0,0,1,0-3h4a1.5,1.5,0,0,1,0,3Z" fill="#3e4166"/>
                <path d="M22.9,22.59a1.587,1.587,0,0,1-.29-.03.985.985,0,0,1-.28-.08,1.3,1.3,0,0,1-.26-.14,2.036,2.036,0,0,1-.23-.181,1.233,1.233,0,0,1-.18-.23,1.3,1.3,0,0,1-.14-.259,1.527,1.527,0,0,1-.09-.281,1.5,1.5,0,0,1,.41-1.359,1.673,1.673,0,0,1,.49-.32,1.522,1.522,0,0,1,.28-.091,1.465,1.465,0,0,1,.59,0,1.522,1.522,0,0,1,.28.091,1.353,1.353,0,0,1,.26.139.993.993,0,0,1,.22.181,1.291,1.291,0,0,1,.19.23,2.076,2.076,0,0,1,.14.26.985.985,0,0,1,.08.28,1.5,1.5,0,0,1,.03.29,1.539,1.539,0,0,1-.44,1.069,1.327,1.327,0,0,1-.22.181,1.3,1.3,0,0,1-.26.14.985.985,0,0,1-.28.08A1.606,1.606,0,0,1,22.9,22.59Z" fill="#3e4166"/>
                <path d="M4.5,28A1.5,1.5,0,0,1,3,26.5V22.229A11.241,11.241,0,0,1,14.229,11H21.5a1.5,1.5,0,0,1,1.482,1.272l.616,4a1.5,1.5,0,0,1-2.965.456L20.213,14H14.229A8.238,8.238,0,0,0,6,22.229V26.5A1.5,1.5,0,0,1,4.5,28Z" fill="#3e4166"/>
                <path d="M35.5,28a1.5,1.5,0,0,1-1.477-1.254L30.7,6.831a4.524,4.524,0,0,0-3.556-3.673l-.939-.188A1.5,1.5,0,1,1,26.794.029l.939.188A7.538,7.538,0,0,1,33.66,6.338l3.319,19.915A1.5,1.5,0,0,1,35.5,28Z" fill="#3e4166"/>
                <path d="M36.5,12h-2a1.5,1.5,0,0,1,0-3H35V8H33.5a1.5,1.5,0,0,1,0-3h3A1.5,1.5,0,0,1,38,6.5v4A1.5,1.5,0,0,1,36.5,12Z" fill="#3e4166"/>
                <path d="M23,14H7A3,3,0,0,1,7,8H23a3,3,0,0,1,0,6Zm0-3h0Z" fill="#3e4166"/>
            </g>
        </svg>
    )
}