import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import {
    Home,
    MerchantsView,
    Categories,
    CategoriesBranches,
    SearchResult,
    Static404,
} from "./pages";

ReactGA.initialize('UA-212818277-1');

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home}></Route>
                        <Route path="/merchants/view/:uuid/:slug" component={MerchantsView}></Route>
                        <Route exact path="/categories" component={Categories}></Route>
                        <Route path="/categories/:code/:categoryName" component={CategoriesBranches}></Route>
                        <Route exact path="/search" component={SearchResult}></Route>
                        <Route exact path="/404" component={Static404}></Route>
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    )
}