import React from 'react';
import { Drawer, Button, Image, Grid, Row, Col, Input, Form } from 'antd';
import BMBranding from '../../components/elements/BMBranding';
import { UserRegister } from '../../services/Users';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import parsePhoneNumber from 'libphonenumber-js';
import { SendSms } from '../../services/Sms';
import { SendMail } from '../../services/Mail';
import AppStoreAndroid from '../../assets/img/app-store-android.png';
import AppStoreApple from '../../assets/img/app-store-apple.png';

const { useBreakpoint } = Grid;

export default function DrawerRightRegister({isVisible, setDrawerRightRegister, setDrawerRightLogin}) {
    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleOnSubmit = async(values) => {
        const mobileNumber = parsePhoneNumber(values.mobile_number, 'PH');
        if (mobileNumber.isValid() === false) {
            Debounce(NotificationManager.error('Mobile number is invalid. Please check and try again.', 'Sorry', 1000), 1000);
            return false;
        }

        if (values.password !== values.confirm_password) {
            Debounce(NotificationManager.error('Passwords are different. Please check and try again.', 'Sorry', 1000), 1000);
            return false;
        }

        const postData = {
            email_address: values.email.toLowerCase(),
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile_number: mobileNumber.number.replace('+', ''),
            user_type_code: 'A002',
            user_status: 1
        }
        const registerResult = await UserRegister(postData);
        if (registerResult.data.status === 'success') {
            SendVerificationCodes(postData.mobile_number, postData.email_address, postData.first_name);
            Debounce(NotificationManager.success('You are now registered. You can now use your email and password to login.', 'Success', 1000), 1000);
            setDrawerRightRegister(false);
        } else {
            Debounce(NotificationManager.error('Email address you provided is already in use. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const SendVerificationCodes = async(mobile_number, email_address, first_name) => {
        const smsRegister = {
            'mobileNumber': mobile_number,
            'firstName': first_name
        }
        await SendSms('register', smsRegister);

        const emailRegister = {
            'toEmail': email_address,
            'firstName': first_name
        }
        await SendMail('register', emailRegister);
    }

    const handleLogin = () => {
        setDrawerRightRegister(false);
        setDrawerRightLogin(true);
    }

    return (
        <Drawer className="bm-drawer" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => setDrawerRightRegister(false)} visible={isVisible}>
            <div className="py-5">
                <Row className="brand-container auth" justify="center">
                    <Col xs={24} md={20} className="text-center">
                        <BMBranding dimensions={{width: 120, marginRight: 120}} />
                        <h1 className="font-weight-900 font-color-grey-base-x5 mt-4">Register</h1>
                        <Form
                            className="mt-4"
                            layout="vertical"
                            form={form}
                            onFinish={handleOnSubmit}>
                            <Form.Item name={'email'} label="Email Address" type={'email'} rules={[{ required: true, message: 'Email address is required.' }]}>
                                <Input placeholder="email address" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'password'} label="Password" type={'password'} rules={[{ required: true, message: 'Password is required.' }]}>
                                <Input.Password placeholder="password" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'confirm_password'} label="Confirm Password" type={'password'} rules={[{ required: true, message: 'Confirm your password.' }]}>
                                <Input.Password placeholder="confirm password" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'first_name'} label="First Name" type={'text'} rules={[{ required: true, message: 'First name is required.' }]}>
                                <Input placeholder="first name" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'last_name'} label="Last Name" type={'text'} rules={[{ required: true, message: 'Last name is required.' }]}>
                                <Input placeholder="last name" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'mobile_number'} label="Mobile Number" type={'text'} rules={[{ required: true, message: 'Mobile number is required.' }]}>
                                <Input placeholder="mobile number" className="bm-input" />
                            </Form.Item>
                            <Button className="btn btn-primary mt-2" size={'large'} block htmlType={'submit'}>Submit</Button>
                        </Form>
                        <div className="my-4">
                            <Button type="link" onClick={handleLogin} className="link link-primary">Back to Login</Button>
                        </div>
                    </Col>
                </Row>
                <div className="mt-4 text-center">
                    <h3>Also available in</h3>
                    <Row gutter={[16, 16]} wrap={false}>
                        <Col span={12} className="text-end">
                            <a href={'https://bit.ly/373QNQ1'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreAndroid} preview={false} width={140} /></a>
                        </Col>
                        <Col span={12} className="text-start">
                            <a href={'https://apple.co/3BANT3p'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreApple} preview={false} width={140} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Drawer>
    )
}