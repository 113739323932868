import React from 'react';
import { Drawer, Button, Grid, Row, Col, Input, Image, Form } from 'antd';
import BMBranding from '../../components/elements/BMBranding';
import { UserLogin } from '../../services/Users';
import { GetUserAddresses } from '../../services/Addresses';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import AppStoreAndroid from '../../assets/img/app-store-android.png';
import AppStoreApple from '../../assets/img/app-store-apple.png';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import { setAuthDetails, setUserAddresses } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightLogin({isVisible, setDrawerRightLogin, setDrawerRightRegister, setDrawerRightForgot}) {
    const dispatch = useDispatch();
    const handleSetAuthDetails = (userDetails) => dispatch(setAuthDetails(userDetails));
    const handleSetUserAddresses = (addresses) => dispatch(setUserAddresses(addresses));

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleOnSubmit = async(values) => {
        const postData = {
            email_address: values.email,
            password: values.password
        }
        const loginResult = await UserLogin(postData);
        if (loginResult.data.status === 'success') {
            loginResult.data.data.session_created_at = moment().format();
            handleSetAuthDetails(loginResult.data.data);
            const userAddressesResult = await GetUserAddresses(loginResult.data.data.user_uuid);
            handleSetUserAddresses(userAddressesResult.data.data);
            Debounce(NotificationManager.success('You are now logged in.', 'Success', 1000), 1000);
            setDrawerRightLogin(false);
        } else {
            Debounce(NotificationManager.error('Email and/or password is incorrect. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleRegister = () => {
        setDrawerRightLogin(false);
        setDrawerRightRegister(true);
    }

    const handleForgot = () => {
        setDrawerRightLogin(false);
        setDrawerRightForgot(true);
    }

    return (
        <Drawer className="bm-drawer" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => setDrawerRightLogin(false)} visible={isVisible}>
            <div className="py-5">
                <Row className="brand-container auth" justify="center">
                    <Col xs={24} md={20} className="text-center">
                        <BMBranding dimensions={{width: 120, marginRight: 120}} />
                        <h1 className="font-weight-900 font-color-grey-base-x5 mt-4">Login</h1>
                        <Form
                            className="mt-4"
                            layout="vertical"
                            form={form}
                            onFinish={handleOnSubmit}>
                            <Form.Item name={'email'} label="Email Address" type={'email'} rules={[{ required: true, message: 'Email address is required.' }]}>
                                <Input placeholder="email address" className="bm-input" />
                            </Form.Item>
                            <Form.Item name={'password'} label="Password" type={'password'} rules={[{ required: true, message: 'Password is required.' }]}>
                                <Input.Password placeholder="password" className="bm-input" />
                            </Form.Item>
                            <Button className="btn btn-primary mt-2" size={'large'} block htmlType={'submit'}>Submit</Button>
                        </Form>
                        <div className="my-4">
                            <Button type="link" onClick={handleRegister} className="link link-primary" block>Register for a Free Account</Button>
                            <Button type="link" className="link link-primary" block onClick={(e) => handleForgot()}>Forgot Password</Button>
                        </div>
                    </Col>
                </Row>
                <div className="mt-4 text-center">
                    <h3>Also available in</h3>
                    <Row gutter={[16, 16]} wrap={false}>
                        <Col span={12} className="text-end">
                            <a href={'https://bit.ly/373QNQ1'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreAndroid} preview={false} width={140} /></a>
                        </Col>
                        <Col span={12} className="text-start">
                            <a href={'https://apple.co/3BANT3p'} target="_blank" rel="noopener noreferrer"><Image src={AppStoreApple} preview={false} width={140} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Drawer>
    )
}