import React from 'react';
import './assets/css/custom.css';
import 'antd/dist/antd.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-notifications/dist/react-notifications.css';
import App from './App';
import { hydrate, render } from 'react-dom';

const rootElement = document.getElementById('root');
const APP = (
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
} else {
    render(APP, rootElement);
}
