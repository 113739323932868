import React from 'react';
import { Drawer, Card, List, Grid, Row, Col, Image } from 'antd';
import { NumFormat } from '../../functions/Math';
import { FilterObject, GetFoodCategoryLabels } from '../../functions/Utilities';
import { GetOrderNotesLabel } from '../../functions/Utilities';
import LoadingOverlay from 'react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader';
import { useSelector } from 'react-redux';

const { useBreakpoint } = Grid;

export default function DrawerRightOrderDetails({isVisible, setDrawerRightOrderDetails, setDrawerRightOrders, selectedOrder, selectedOrderDetails, setSelectedOrder, setSelectedOrderDetails}) {
    const userDetails = useSelector(state => state.reducer.userDetails);
    const screens = useBreakpoint();
    let totalPrice = selectedOrderDetails === null ? 0 : selectedOrderDetails.orderItems.reduce((n, {total_price_posted}) => n + total_price_posted, 0);

    const handleClose = () => {
        setDrawerRightOrderDetails(false);
        setDrawerRightOrders(true);
        setSelectedOrder(null);
        setSelectedOrderDetails(null);
    }

    const ItemCount = ({item, index}) => {
        return (
            <div className="counter">{index + 1}</div>
        )
    }

    const ItemTitle = ({item}) => {
        const itemDetails = FilterObject(selectedOrderDetails.merchantItems.items, item.item_uuid, 'item_uuid');
        const variationDetails = FilterObject(selectedOrderDetails.merchantItems.variations, item.variation_uuid, 'variation_uuid');
        return (
            <Row gutter={[16, 16]} wrap={false}>
                <Col flex={'auto'}>{`(${item.quantity}x) ${itemDetails[0].item_name} ${variationDetails[0].variation_name}`}</Col>
                <Col flex={'100px'} className="text-end">P{NumFormat((item.variation_amount_posted * item.quantity) / 100, 2)}</Col>
            </Row>
        )
    }

    const ItemMeta = ({item}) => {
        let requiredLabelArray = [];
        let optionalLabelArray = [];
        let requiredArray = JSON.parse(item.add_on_required);
        let optionalArray = JSON.parse(item.add_on_optional);

        requiredArray.map(itemRequired => {
            const addOnDetails = FilterObject(selectedOrderDetails.merchantItems.add_ons, itemRequired.add_on_uuid, 'add_on_uuid');
            requiredLabelArray.push(`(${item.quantity}x) ${addOnDetails[0].add_on_name}`);
            return null;
        });
        optionalArray.map(itemOptional => {
            const addOnDetails = FilterObject(selectedOrderDetails.merchantItems.add_ons, itemOptional.add_on_uuid, 'add_on_uuid');
            optionalLabelArray.push(`(${item.quantity}x) ${addOnDetails[0].add_on_name}`);
            return null;
        });

        return (
            <div>
                {
                    requiredLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{requiredLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_required_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
                {
                    optionalLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{optionalLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_optional_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
            </div>
        )
    }

    return (
        <Drawer className="bm-drawer" title="Order Details" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose(false)} visible={isVisible}>
            {
                selectedOrderDetails === null &&
                <LoadingOverlay active={true} spinner={<PulseLoader color="#ff5cf4" />}></LoadingOverlay>
            }
            {
                selectedOrderDetails !== null &&
                <div>
                    <div className="mb-4">
                        <h4>ORDERING FROM</h4>
                        <div className="merchant-details">
                            <Image src={selectedOrderDetails.merchantDetails.merchant_logo_file_url} preview={false} />
                            <div className="item-meta-container">
                                <h3 className="mb-1">{selectedOrderDetails.merchantDetails.merchant_name}</h3>
                                <div>{selectedOrderDetails.branchDetails.branch_name}</div>
                                <div>{GetFoodCategoryLabels(selectedOrderDetails.branchDetails.food_category_types)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h4>DELIVER TO</h4>
                        <Card className="bm-card">
                            <div className="font-weight-500">{userDetails.first_name} {userDetails.last_name}</div>
                            <div className="font-color-grey-base-x3">{userDetails.email_address}</div>
                            <div className="font-color-grey-base-x3">{userDetails.mobile_number}</div>
                            <div className="font-color-grey-base-x3">{`${selectedOrderDetails.addressDetails.street} ${selectedOrderDetails.addressDetails.city_mun} ${selectedOrderDetails.addressDetails.prov_region} ${selectedOrderDetails.addressDetails.zip_code}`}</div>
                        </Card>
                    </div>

                    <div className="mb-4">
                        <h4>BAG ITEMS</h4>
                        <Card className="bm-card">
                            <List
                                className="bag-items-list"
                                itemLayout="horizontal"
                                dataSource={selectedOrderDetails.orderItems}
                                renderItem={(item, index) => (
                                    <List.Item
                                        key={item.key}>
                                        <List.Item.Meta
                                            avatar={<ItemCount item={item} index={index} />}
                                            title={<ItemTitle item={item} />}
                                            description={<ItemMeta item={item} />}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </div>

                    <div className="mb-4">
                        <h4>DELIVERY PARTNER</h4>
                        <Card className="bm-card">
                            { selectedOrderDetails.deliveryPartnerDetails.first_name } { selectedOrderDetails.deliveryPartnerDetails.last_name.charAt(0) + '.' }
                            {
                                selectedOrder.order_status === 1 &&
                                <div>
                                    <div>{selectedOrderDetails.deliveryPartnerDetails.mobile_number}</div>
                                    <Row gutter={[16, 16]} wrap={false} className="mt-2">
                                        <Col span={12}>
                                            <a className="ant-btn btn btn-primary btn-border ant-btn-block" block href={`tel:${selectedOrderDetails.deliveryPartnerDetails.mobile_number}`}>Call</a>
                                        </Col>
                                        <Col span={12}>
                                            <a className="ant-btn btn btn-primary btn-border ant-btn-block" block href={`sms:${selectedOrderDetails.deliveryPartnerDetails.mobile_number}`}>Send SMS</a>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card>
                    </div>

                    <div className="mb-4">
                        <h4>NOTE TO DELIVERY PARTNER</h4>
                        <Card className="bm-card">
                            {GetOrderNotesLabel(selectedOrder.order_notes)}
                        </Card>
                    </div>
                    
                    <Row gutter={[16, 16]} wrap={false} className="mb-1">
                        <Col flex={'auto'} className="font-weight-500">Order Tracking No</Col>
                        <Col flex={'100px'} className="font-weight-500 text-end">{selectedOrder.order_tracking_no}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false} className="mb-1">
                        <Col flex={'auto'} className="font-weight-500">Delivery Fee <span className="font-color-grey-base-x3 font-size-xs">({NumFormat((selectedOrder.charge_distance_meters / 1000), 2)}km)</span></Col>
                        <Col flex={'100px'} className="font-weight-500 text-end">P{NumFormat((selectedOrder.order_delivery_due_amount / 100), 2)}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false} className="mb-1">
                        <Col flex={'auto'} className="font-weight-500">Items</Col>
                        <Col flex={'100px'} className="font-weight-500 text-end">P{NumFormat(totalPrice / 100, 2)}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false} className="mb-1">
                        <Col flex={'auto'} className="font-weight-500">Booking Fee</Col>
                        <Col flex={'100px'} className="font-weight-500 text-end">P{NumFormat((selectedOrder.charge_booking_fee_amount / 100), 2)}</Col>
                    </Row>
                    {
                        selectedOrder.voucher_uuid &&
                        <Row gutter={[16, 16]} wrap={false} className="mb-1">
                            <Col flex={'auto'} className="font-weight-500">LESS Voucher ({selectedOrder.voucher_code})</Col>
                            <Col flex={'100px'} className="font-weight-500 text-end">-P{NumFormat((selectedOrder.voucher_amount / 100), 2)}</Col>
                        </Row>
                    }
                    <Row gutter={[16, 16]} wrap={false} className="mt-2">
                        <Col flex={'auto'} className="font-weight-500 font-size-md font-weight-700">Total</Col>
                        <Col flex={'100px'} className="font-weight-500 font-size-md font-weight-700 text-end">P{NumFormat((selectedOrder.order_total_amount / 100), 2)}</Col>
                    </Row>
                </div>
            }
        </Drawer>
    )
}