import moment from 'moment';
import { FoodCategoryTypes } from '../data/FoodCategoryTypes';
import { GetDrivingDistance } from '../services/Maps';
import { OrderNotes } from '../data/OrderNotes';

export const Debounce = (callback, delay = 250) => {
	let timeoutId
    return (...args) => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}

export const FilterObject = (array, value, key) => {
    return array.filter(key ? a => a[key] === value : a => Object.keys(a).some(k => a[k] === value));
}

export const GetFoodCategoryLabels = (values) => {
    let string = '';
    if (values !== '') {
        let labelArray = [];
        let valuesArray = values.split(',');
        valuesArray.forEach(item => {
            const result = FoodCategoryTypes.find(itemCategory => itemCategory.code === item);
            labelArray.push(result.categoryName);
        });
        string = labelArray.join(', ');
    }
    return string;
}

export const ConvertToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const FormatItemsList = (menuCategories, itemsData, merchantDetails) => {
    let formattedData = menuCategories;
    for (const [keyCategory, valueCategory] of Object.entries(menuCategories)) {
        formattedData[keyCategory].data = []
        for (const [keyItem, valueItem] of Object.entries(itemsData.items)) {
            if (valueCategory.menu_category_uuid === valueItem.menu_category_uuid) {
                const variation = itemsData.variations.find(({ item_uuid }) => item_uuid === valueItem.item_uuid);
                valueItem.starts_at_price = variation.variation_price * ((merchantDetails.price_rate / 100) + 1);
                formattedData[keyCategory].data.push(valueItem);
            }
        }
    }
    return formattedData;
}

export const MergeArray = (array) => {
    if (array.length > 0) {
        let mergedArray = [];
        array.map(item => {
            mergedArray.push(...item);
        })
        return mergedArray;
    } else {
        return [];
    }
};

export const MergeArrayObjects = (array) => {
    let mergedArray = [];
    for (const key of Object.keys(array)) {
        if (typeof array[key] === 'object') {
            for (const key2 of Object.keys(array[key])) {
                mergedArray.push(array[key][key2]);
            }
        } else {
            mergedArray.push(array[key]);
        }
    }
    return mergedArray;
}

export const IncludesMultiArray = (array, value) => {
    let result = false;
    array.forEach(item => {
        item.forEach(itemValue => {
            if (itemValue === value) {
                result = true;
            }
        })
    })
    return result;
}

export const GetDeliveryFeeActual = async(distanceDetails, serviceArea) => {
    const resultDistance = distanceDetails.distance;
    const resultTravelTime = distanceDetails.duration;
    const computedDistance = (resultDistance.value - (serviceArea.delivery_min_radius * 10));
    if (computedDistance <= 0) {
        return {
            actual_fee: serviceArea.delivery_min_fee,
            actual_distance: resultDistance.value,
            travel_time_est: resultTravelTime.text
        }
    } else {
        return {
            actual_fee: serviceArea.delivery_min_fee + ((computedDistance / 1000) * serviceArea.delivery_excess_per_km),
            actual_distance: resultDistance.value,
            travel_time_est: resultTravelTime.text
        }
    }
}

export const GetOrderNotesLabel = (value) => {
    const result = OrderNotes.find(itemNote => itemNote.value === value);
    return result.note;
}

export const Capitalize = (string) => {
    var exceptions = ['II', 'III', 'IV', 'VI', 'VII', 'VIII', 'IX', 'X1', 'XII', 'XIII', 'IV-A', 'IV-B', 'BARMM', 'CAR', 'NCR'];
    let arrString = string.split(' ');
    for (var i = 0; i < arrString.length; i++) {
    	if (!exceptions.includes(arrString[i])) {
            if (arrString[i].includes('-')) {
                let arrStringDashed = arrString[i].split('-');
                for (var d = 0; d < arrStringDashed.length; d++) {
                    arrStringDashed[d] = arrStringDashed[d].toLowerCase();
                    if (arrStringDashed[d].charAt(0) === '(') {
                        arrStringDashed[d] = '(' + arrStringDashed[d].charAt(1).toUpperCase() + arrStringDashed[d].slice(2);
                    } else {
                        arrStringDashed[d] = arrStringDashed[d].charAt(0).toUpperCase() + arrStringDashed[d].slice(1);
                    }
                }
                arrString[i] = arrStringDashed.join('-');
            } else {
                arrString[i] = arrString[i].toLowerCase();
                if (arrString[i].charAt(0) === '(') {
                    arrString[i] = '(' + arrString[i].charAt(1).toUpperCase() + arrString[i].slice(2);
                } else {
                    arrString[i] = arrString[i].charAt(0).toUpperCase() + arrString[i].slice(1);
                }
            }
        }
    }
    return arrString.join(' ');
}