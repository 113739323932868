import React from 'react';
import { Drawer, Button, Grid, Card } from 'antd';
import { useSelector } from 'react-redux';

const { useBreakpoint } = Grid;

export default function DrawerRightProfileView({isVisible, setDrawerRightProfileView, setDrawerRightAccount, setDrawerRightProfileUpdate, setDrawerRightProfilePassword}) {
    const userDetails = useSelector(state => state.reducer.userDetails);

    const screens = useBreakpoint();

    const handleClose = () => {
        setDrawerRightProfileView(false);
        setDrawerRightAccount(true);
    }

    const handleProfileUpdate = () => {
        setDrawerRightProfileView(false);
        setDrawerRightProfileUpdate(true)
    }

    const handleProfilePassword = () => {
        setDrawerRightProfileView(false);
        setDrawerRightProfilePassword(true)
    }

    return (
        <Drawer className="bm-drawer" title={'Profile Details'} placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose()} visible={isVisible}>
            {
                userDetails &&
                <Card className="bm-card pointer mb-3">
                    <h3 className="mb-2">{`${userDetails.first_name} ${userDetails.last_name}`}</h3>
                    <h5 className="mb-0 font-color-grey-base-x2">EMAIL ADDRESS</h5>
                    <div className="mb-2">{userDetails.email_address}</div>
                    <h5 className="mb-0 font-color-grey-base-x2">MOBILE NUMBER</h5>
                    <div>{userDetails.mobile_number}</div>
                    <div className="mt-4">
                        <Button type="primary" className="btn btn-primary" size="large" block onClick={(e) => handleProfileUpdate()}>Update Profile</Button>
                        <Button type="primary" className="btn btn-primary btn-border mt-3" size="large" block onClick={(e) => handleProfilePassword()}>Update Password</Button>
                    </div>
                </Card>
            }
        </Drawer>
    )
}