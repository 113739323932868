export const ConfigKeys = {
    BilimokoBaseUrl: {
        value: 'https://api.bilimoko.net/v1'
        // value: 'https://api-dev.bilimoko.net/v1'
    },
    BilimokoChatUrl: {
        value: 'https://api.bilimoko.net:2053'
        // value: 'https://api-dev.bilimoko.net:2053'
    },
    MapBox: {
        key: 'pk.eyJ1IjoidGFyb2tpbnMiLCJhIjoiY2tuOTF0Mm9yMGV4MTJ2cGlwMTk0dHQ1diJ9.ZKHRRQoxMXvdSIW2Z1cMmw'
    },
    ImgBB: {
        key: '6e10e318ceda354ed1544a6c5fb2349b'
    },
    SendGrid: {
        key: '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'
    },
    GoogleServices: {
        key: 'AIzaSyCWct3fOaNAb_nsTwaL9pputyFlNFr1ex0'
    }
}