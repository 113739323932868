import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Grid } from 'antd';
import PageLayout from '../../components/layout/PageLayout';
import SectionWrapper from '../../components/layout/SectionWrapper';
import Slider from 'react-slick';
import { useHistory, Link } from 'react-router-dom';
import { GetFoodCategoryLabels, ConvertToSlug } from '../../functions/Utilities';
import { GetDistance, NumFormat } from '../../functions/Math';
import { FoodCategoryTypesFeatured } from '../../data/FoodCategoryTypesFeatured';
import { GetServiceAreaSpotlights } from '../../services/Spotlights';
import { GetAreaTopBranches, GetAreaRecentBranches, GetServiceAreaBranches } from '../../services/MerchantBranches';
import { GetServiceAreaFeatures } from '../../services/Features';
import { GetVouchers } from '../../services/Vouchers';
import { setAreaVouchers } from '../../store/Action';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import { useSelector, useDispatch } from 'react-redux';

import Icon from '@ant-design/icons';
import Motorcycle from '../../assets/icons/Motorcycle';

const { useBreakpoint } = Grid;

export default function Home() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [merchantSpotlights, setMerchantSpotlights] = useState(null);
    const [topAreaBranches, setTopAreaBranches] = useState(null);
    const [recentAreaBranches, setRecentAreaBranches] = useState(null);
    const [serviceAreaBranches, setServiceAreaBranches] = useState(null);
    const [features, setFeatures] = useState(null);
    const screens = useBreakpoint();
    
    const currentLocation = useSelector(state => state.reducer.currentLocation);
    const serviceAreas = useSelector(state => state.reducer.serviceAreas);
    const serviceArea = useSelector(state => state.reducer.serviceArea);

    const handleSetAreaVouchers = (vouchers) => dispatch(setAreaVouchers(vouchers));

    const getMerchants = () => {
        setIsLoading(true);
        setMerchantSpotlights(null);
        setTopAreaBranches(null);
        setRecentAreaBranches(null);
        setServiceAreaBranches(null);
        setFeatures(null);
        if (currentLocation) {
            Promise.all([
                GetServiceAreaSpotlights(serviceArea.service_area_uuid, 5),
                GetAreaTopBranches(serviceArea.service_area_uuid, 10),
                GetAreaRecentBranches(serviceArea.service_area_uuid, 10),
                GetServiceAreaFeatures(serviceArea.service_area_uuid)
            ]).then(result => {
                result[1].data.data.map((value, key) => {
                    const locationPoints = {
                        'lat1': currentLocation ? currentLocation.lat : serviceArea.area_lat,
                        'lng1': currentLocation ? currentLocation.lng : serviceArea.area_lng,
                        'lat2': value.map_lat,
                        'lng2': value.map_lng
                    }
                    result[1].data.data[key].distance = GetDistance(locationPoints);
                    return null;
                });
                result[2].data.data.map((value, key) => {
                    const locationPoints = {
                        'lat1': currentLocation ? currentLocation.lat : serviceArea.area_lat,
                        'lng1': currentLocation ? currentLocation.lng : serviceArea.area_lng,
                        'lat2': value.map_lat,
                        'lng2': value.map_lng
                    }
                    result[2].data.data[key].distance = GetDistance(locationPoints);
                    return null;
                });
                setMerchantSpotlights(result[0].data.data);
                setTopAreaBranches(result[1].data.data);
                setRecentAreaBranches(result[2].data.data);
                setFeatures(result[3].data.data);
                handleGenerateLinks();
                setIsLoading(false);
            });
        }
    }

    const getVouchers = async() => {
        if (serviceArea) {
            const resultVouchers = await GetVouchers(serviceArea.service_area_uuid, moment().format('YYYY-MM-DD'));
            handleSetAreaVouchers(resultVouchers.data.data);
        }
    }

    const handleMerchantBranchClick = (item) => {
        history.push('/merchants/view/' + item.branch_uuid + '/' + ConvertToSlug(item.branch_name));
    }

    const handleCategoryClick = (item, e) => {
        if (item.id === '999') {
            history.push('/categories');
        } else {
            history.push('/categories/' + item.value + '/' + ConvertToSlug(item.label));
        }
    }

    const handleGenerateLinks = async() => {
        let branchesResult = await Promise.all(serviceAreas.map(async(item) => {
            const areaBranchesResult = await GetServiceAreaBranches(item.service_area_uuid);
            return areaBranchesResult.data.data;
        }));
        let branchesResultMerged = [];
        branchesResult.map(itemGroup => {
            itemGroup.map(item => {
                branchesResultMerged.push(item);
                return null;
            });
            return null;
        })
        console.log(branchesResultMerged.length);
        setServiceAreaBranches(branchesResultMerged);
    }
    
    useEffect(() => {
        getMerchants();
        getVouchers();
        ReactGA.pageview(window.location.pathname);
    }, [serviceArea]);

    const FoodCategoryItem = (item, key) => {
        if (item.id === '999') {
            return (
                <Col xs={{span: 6}} sm={{span: 4}} md={{span: 3}} lg={{span: 2}} className="text-center">
                    <div className="category-item" onClick={(e) => handleCategoryClick(item, e)}>
                        <div className="category-icon"><img src={'https://bilimoko.net/drive/assets/img/Fmore.png'} /></div>
                        <label className="category-label">{item.label}</label>
                    </div>      
                </Col>
            )
        } else {
            let count = 11;
            (screens.xl === false) ? count = 11 : count = count;
            (screens.lg === false) ? count = 7 : count = count;
            (screens.md === false) ? count = 5 : count = count;
            (screens.sm === false) ? count = 3 : count = count;
            if (count > key) {
                return (
                    <Col xs={{span: 6}} sm={{span: 4}} md={{span: 3}} lg={{span: 2}} className="text-center">
                        <div className="category-item" onClick={(e) => handleCategoryClick(item, e)}>
                            <div className="category-icon"><img src={'https://bilimoko.net/drive/assets/img/' + item.value + '.png'} /></div>
                            <label className="category-label">{item.label}</label>
                        </div>      
                    </Col>
                )
            }
        }
    }

    const sliderSettings = {
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <PageLayout loading={isLoading} serviceAreas={serviceAreas}>
            {/* food categories */}
            <SectionWrapper>
                <h4 className="font-color-grey-base-x3">FOOD CATEGORIES</h4>
                <Row justify="center">
                    {
                        FoodCategoryTypesFeatured.map((item, key) => {
                            return FoodCategoryItem(item, key);
                        })
                    }
                </Row>
            </SectionWrapper>
            
            {/* merchant spotlight */}
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">Crave it? Get it!</h1>
                    <h2 className="section-subtitle">Here are today's merchant spotlight.</h2>
                </div>
                {
                    isLoading === false && merchantSpotlights &&
                    <Slider {...sliderSettings} className="bm-slider bm-slider-spotlight">
                        {
                            merchantSpotlights.map(item => {
                                return (
                                    <div className="bm-slider-container cursor-pointer"
                                        onClick={() => handleMerchantBranchClick(item)}>
                                        <div className="bm-slider-inner-container">
                                            <div className="bm-badge" style={{top: '16px', left: '16px'}}>{item.spotlight_alt_title}</div>
                                            <img src={item.file_url} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                }
            </SectionWrapper>

            {/* top merchants */}
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">Top Partner Merchants</h1>
                    <h2 className="section-subtitle">Here's what people are craving for.</h2>
                </div>
                {
                    isLoading === false && topAreaBranches &&
                    <Slider {...sliderSettings} className="bm-slider bm-slider-merchants">
                        {
                            topAreaBranches.length >= 5 && topAreaBranches.map(item => {
                                return (
                                    <div className="bm-slider-container">
                                        {
                                            (item.is_free_delivery === 1) &&
                                            <div className="bm-badge">FREE DELIVERY</div>
                                        }
                                        <Card
                                            style={{ width: '90%', borderRadius: 12 }}
                                            cover={<img alt={item.branch_name} src={item.listing_photo_file_url} />}
                                            bordered={false}
                                            className="bm-card-merchant"
                                            onClick={() => handleMerchantBranchClick(item)}>
                                            <div className="bm-slider-meta-container">
                                                <h4>{item.branch_name}</h4>
                                                <div className="bm-slider-meta-item">{NumFormat(item.distance, 2)}km</div>
                                                <div className="bm-slider-meta-item">{GetFoodCategoryLabels(item.food_category_types)}</div>
                                                {
                                                    (item.is_free_delivery === 1) &&
                                                    <div className="min-delivery-wrapper">
                                                        <div className="min-delivery-icon">
                                                            <Icon component={Motorcycle} />
                                                        </div>
                                                        <div className="min-delivery-label">Min Spend P{NumFormat(item.min_free_delivery / 100)}</div>
                                                    </div>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                }
            </SectionWrapper>

            {/* recent merchants */}
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">Recently Added</h1>
                    <h2 className="section-subtitle">You may want to try these newly added merchants.</h2>
                </div>
                {
                    isLoading === false && recentAreaBranches &&
                    <Slider {...sliderSettings} className="bm-slider bm-slider-merchants">
                        {
                            recentAreaBranches.map(item => {
                                return (
                                    <div className="bm-slider-container">
                                        {
                                            (item.is_free_delivery === 1) &&
                                            <div className="bm-badge">FREE DELIVERY</div>
                                        }
                                        <Card
                                            style={{ width: '90%', borderRadius: 12 }}
                                            cover={<img alt={item.branch_name} src={item.listing_photo_file_url} />}
                                            bordered={false}
                                            className="bm-card-merchant"
                                            onClick={() => handleMerchantBranchClick(item)}>
                                            <div className="bm-slider-meta-container">
                                                <h4>{item.branch_name}</h4>
                                                <div className="bm-slider-meta-item">{NumFormat(item.distance, 2)}km</div>
                                                <div className="bm-slider-meta-item">{GetFoodCategoryLabels(item.food_category_types)}</div>
                                                {
                                                    (item.is_free_delivery === 1) &&
                                                    <div className="min-delivery-wrapper">
                                                        <div className="min-delivery-icon">
                                                            <Icon component={Motorcycle} />
                                                        </div>
                                                        <div className="min-delivery-label">Min Spend P{NumFormat(item.min_free_delivery / 100)}</div>
                                                    </div>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                }
            </SectionWrapper>

            {/* features */}
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">Features</h1>
                    <h2 className="section-subtitle">Some information that might interest you.</h2>
                </div>
                {
                    isLoading === false && features &&
                    <Slider {...sliderSettings} className="bm-slider bm-slider-spotlight">
                        {
                            features.map(item => {
                                return (
                                    <div className="bm-slider-container">
                                        <div className="bm-slider-inner-container">
                                            <img alt={item.feature_title} src={item.file_url} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                }
            </SectionWrapper>

            <Helmet prioritizeSeoTags>
                <title>Order Food via bilimoko | The best food delivery in the Philippines</title>
                <meta name="description" content='Food delivery booking app bilimoko is created to smooth out your online food ordering experience. Use the app with no delay in food delivery.' />
                <meta property="og:title" content='Order Food via bilimoko | The best food delivery in the Philippines' />
                <meta property="og:url" content='https://bilimoko.net' />
                <meta property="og:type" content="article" />
                <meta property="og:description" content='Food delivery booking app bilimoko is created to smooth out your online food ordering experience. Use the app with no delay in food delivery.' />
                <meta property="og:image" content='https://res.cloudinary.com/falcomtech/image/upload/v1636266525/bilimoko/IndexOpenGraph_me09bb.png' />
                <meta property="twitter:title" content='Order Food via bilimoko | The best food delivery in the Philippines' />
                <meta property="twitter:description" content='Food delivery booking app bilimoko is created to smooth out your online food ordering experience. Use the app with no delay in food delivery.' />
                <meta property="twitter:image" content='https://res.cloudinary.com/falcomtech/image/upload/v1636266525/bilimoko/IndexOpenGraph_me09bb.png' />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* generated links */}
            <div className="d-none generated links">
                {
                    serviceAreaBranches &&
                    serviceAreaBranches.map(item => {
                        return <Link to={`/merchants/view/${item.branch_uuid}/${ConvertToSlug(item.branch_name)}`} className="d-none">{item.branch_name}</Link>
                    })
                }
            </div>
        </PageLayout>
    )
}
