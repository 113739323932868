import React, { useState, useEffect } from 'react';
import { Drawer, Alert, Button, List, Grid, Row, Col, Image, Card, Radio, Space, Modal, Spin } from 'antd';
import { Debounce, GetFoodCategoryLabels, FilterObject, GetDeliveryFeeActual } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import { NumFormat, GetPrice } from '../../functions/Math';
import { SearchDeliveryPartner } from '../../services/DeliveryPartners';
import { AddOrder } from '../../services/Orders';
import { AddOrderItems } from '../../services/OrderItems';
import { AddTracking } from '../../services/Trackings';
import { SendSms } from '../../services/Sms';
import { SendMessage } from '../../services/Messages';
import { OrderNotes } from '../../data/OrderNotes';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useJsApiLoader, GoogleMap, DirectionsService } from '@react-google-maps/api';
import { ConfigKeys } from '../../config/ConfigKeys';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVoucher, setSelectedVoucherItem, setSelectedBranch, setSelectedMerchant, emptyBagItems } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightCheckOut({isVisible, setDrawerRightCheckOut, setDrawerRightBag, setDrawerRightLogin, setDrawerRightRegister, setDrawerRightAddressAdd}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedDeliveryFee, setSelectedDeliveryFee] = useState(null);
    const [selectedDeliveryDistance, setSelectedDeliveryDistance] = useState(0);
    const [deliveryTimeEstimate, setDeliveryTimeEstimate] = useState(null);
    const [orderNote, setOrderNote] = useState(null);
    
    const userDetails = useSelector(state => state.reducer.userDetails);
    const userAddresses = useSelector(state => state.reducer.userAddresses);
    const bagItems = useSelector(state => state.reducer.bagItems);
    const serviceArea = useSelector(state => state.reducer.serviceArea);
    const selectedBranch = useSelector(state => state.reducer.selectedBranch);
    const selectedMerchant = useSelector(state => state.reducer.selectedMerchant);
    const selectedVoucher = useSelector(state => state.reducer.selectedVoucher);
    const selectedVoucherItem = useSelector(state => state.reducer.selectedVoucherItem);
    const handleSetSelectedBranch = (branchDetails) => dispatch(setSelectedBranch(branchDetails));
    const handleSetSelectedMerchant = (merchantDetails) => dispatch(setSelectedMerchant(merchantDetails));
    const handleSetSelectedVoucher = (voucherDetails) => dispatch(setSelectedVoucher(voucherDetails));
    const handleSetSelectedVoucherItem = (itemDetails) => dispatch(setSelectedVoucherItem(itemDetails));
    const handleEmptyBagItems = (items) => dispatch(emptyBagItems(items));
    const totalPrice = bagItems.reduce((n, {total_price_posted}) => n + total_price_posted, 0);
    const [voucherDetails, setVoucherDetails] = useState({voucherUuid: null, voucherCode: '', voucherAmount: 0});
    const [promoDeliveryFee, setPromoDeliveryFee] = useState(0);
    const [riderName, setRiderName] = useState('');
    
    const [buttonLabel, setButtonLabel] = useState('Place Order');
    const [searchVisible, setSearchVisible] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [timer, setTimer] = useState(0);

    const [directionsOrigin, setDirectionsOrigin] = useState({lat: 0, lng: 0});
    const [directionsDestination, setDirectionsDestination] = useState({lat: 0, lng: 0});
    const [selectedDistanceDetails, setSelectedDistanceDetails] = useState({});
    
    const screens = useBreakpoint();
    
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: ConfigKeys.GoogleServices.key
    });

    const handleAddressSelection = async(e) => {
        setSelectedAddress(e.target.value);
        const selectedAddressTemp = FilterObject(userAddresses, e.target.value, 'address_uuid');
    
        setDirectionsOrigin({
            ...directionsOrigin,
            lat: selectedBranch.map_lat,
            lng: selectedBranch.map_lng,
        });

        setDirectionsDestination({
            ...directionsDestination,
            lat: selectedAddressTemp[0].map_lat,
            lng: selectedAddressTemp[0].map_lng,
        });
    }

    const handleVoucherSelection = (deliveryFee) => {
        if (selectedVoucher !== null) {
            let voucherAmountTemp = 0;
            switch(selectedVoucher.discount_type) {
                case 'delivery-fixed':
                    voucherAmountTemp = (Math.ceil(deliveryFee / 100) * 100 >= selectedVoucher.discount_type_value) ? selectedVoucher.discount_type_value : Math.ceil(deliveryFee / 100) * 100;
                    break;
                case 'delivery-percentage':
                    voucherAmountTemp = deliveryFee - ((selectedVoucher.discount_type_value / 100) / 100) * Math.ceil(deliveryFee / 100) * 100;
                    break;
                case 'order-fixed':
                    voucherAmountTemp = selectedVoucher.discount_type_value;
                    break;
                case 'order-percentage':
                    if (typeof selectedVoucherItem === 'undefined' || selectedVoucherItem === null) {
                        voucherAmountTemp = ((selectedVoucher.discount_type_value / 100) / 100) * totalPrice;
                    } else {
                        let affectedItem = FilterObject(bagItems, selectedVoucherItem.item_uuid, 'item_uuid');
                        voucherAmountTemp = ((selectedVoucher.discount_type_value / 100) / 100) * (affectedItem[0].variation_amount_posted * affectedItem[0].quantity);
                    }
                    break;
                default:
                    break;
            }
            setVoucherDetails({voucherUuid: selectedVoucher.voucher_uuid, voucherCode: selectedVoucher.voucher_code, voucherAmount: voucherAmountTemp});
        }
    }

    const handleCloseCheckOut = () => {
        setDrawerRightCheckOut(false);
        setDrawerRightBag(true);
        setSelectedAddress(null)
        setOrderNote(null);
    }

    const handleOpenLogin = () => {
        setDrawerRightCheckOut(false);
        setDrawerRightLogin(true);
    }

    const handleOpenRegister = () => {
        setDrawerRightCheckOut(false);
        setDrawerRightRegister(true);
    }

    const ItemCount = ({item, index}) => {
        return (
            <div className="counter">{index + 1}</div>
        )
    }

    const ItemTitle = ({item}) => {
        return (
            <Row gutter={[16, 16]} wrap={false}>
                <Col flex={'auto'}>{`(${item.quantity}x) ${item.item_name} ${item.variation_name}`}</Col>
                <Col flex={'100px'} className="text-end">P{NumFormat((item.variation_amount_posted * item.quantity) / 100, 2)}</Col>
            </Row>
        )
    }

    const ItemMeta = ({item}) => {
        let requiredLabelArray = [];
        let optionalLabelArray = [];
        item.add_on_required.map(itemRequired => {
            return requiredLabelArray.push(`(${item.quantity}x) ${itemRequired.add_on_name}`);
        });
        item.add_on_optional.map(itemOptional => {
            return optionalLabelArray.push(`(${item.quantity}x) ${itemOptional.add_on_name}`);
        });
        return (
            <div>
                {
                    requiredLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{requiredLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_required_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
                {
                    optionalLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{optionalLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_optional_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
            </div>
        )
    }

    const handlePlaceOrderButton = () => {
        if (selectedDeliveryFee && orderNote) setIsDisabled(false);
    }

    const IconLoader = () => {
        return (
            <LoadingOutlined style={{fontSize: 35, color: '#ff5cf4'}} spin />
        )
    }

    const handleSearchDeliveryPartner = async () => {
        let counter = 30;
        setIsDisabled(true);
        setSearchVisible(true);
        let intervalCounter = setInterval(async function() {
            if (counter >= 0) {
                setTimer(counter);
                setButtonLabel('Searching for Delivery Partners (' + counter + ')');
                counter--;
            } else {
                setButtonLabel('Place Order');
                setIsDisabled(false);
                clearInterval(intervalCounter);
                setSearchVisible(false);
                Debounce(NotificationManager.error('Sorry, we could not find any available delivery partners at the moment. Please try again in a couple of minutes.', 'No Riders Available', 3000), 3000);
            }
        }, 1000);

        // submit action
        let counterSubmit = 0;
        let intervalCounterSubmit = setInterval(async function() {
            if (counterSubmit <= 2) {
                const deliveryPartnerSearchResult = await SearchDeliveryPartner(selectedBranch.service_area_uuid);
                if (deliveryPartnerSearchResult.data.status === 'success') {
                    if (deliveryPartnerSearchResult.data.data.length > 0) {
                        clearInterval(intervalCounter);
                        clearInterval(intervalCounterSubmit);
                        setSearchVisible(false);
                        
                        const deliveryPartnerDetails = deliveryPartnerSearchResult.data.data[0];

                        let order_total_amount = 0;
                        let order_merchant_due_amount = 0;
                        let order_merchant_commission_amount = 0;
                        let order_merchant_price_rate_amount = 0;
                        let order_admin_due_amount = 0;
                        let bagItemsPost = [];

                        // generate order total amount
                        bagItems.map(function(item, key) {
                            order_total_amount = order_total_amount + item.total_price_posted;
                            order_merchant_due_amount = order_merchant_due_amount + (item.total_price - (selectedMerchant.commission_rate === 0 ? 0 : item.total_price * (selectedMerchant.commission_rate / 100)));
                            order_merchant_commission_amount = order_merchant_commission_amount + parseInt(((item.total_price / 100) * (selectedMerchant.commission_rate / 100)) * 100);
                            order_merchant_price_rate_amount = order_merchant_price_rate_amount + (GetPrice(Math.ceil(item.total_price / 100), selectedMerchant.price_rate)) * 100 - item.total_price;
                            return null;
                        });

                        order_admin_due_amount = order_total_amount - order_merchant_due_amount;
                        
                        if (selectedVoucher) {
                            switch(selectedVoucher.discount_type) {
                                case 'delivery-fixed':
                                    // todo fix merchant sharing
                                    // order_merchant_due_amount = order_merchant_due_amount;
                                    order_admin_due_amount = order_admin_due_amount - voucherDetails.voucherAmount;
                                    break;
                                case 'delivery-percentage':
                                    order_merchant_due_amount = order_merchant_due_amount - (selectedVoucher.share_merchant_value * 100 / selectedVoucher.discount_type_value / 100 * Math.ceil(voucherDetails.voucherAmount));
                                    order_admin_due_amount = order_admin_due_amount - (selectedVoucher.share_platform_value * 100 / selectedVoucher.discount_type_value / 100 * Math.ceil(voucherDetails.voucherAmount));
                                    break;
                                case 'order-fixed':
                                    order_merchant_due_amount = order_merchant_due_amount - selectedVoucher.share_merchant_value;
                                    order_admin_due_amount = order_admin_due_amount - selectedVoucher.share_platform_value;
                                    break;
                                case 'order-percentage':
                                    order_merchant_due_amount = order_merchant_due_amount - (selectedVoucher.share_merchant_value * 100 / selectedVoucher.discount_type_value / 100 * voucherDetails.voucherAmount);
                                    order_admin_due_amount = order_admin_due_amount - (selectedVoucher.share_platform_value * 100 / selectedVoucher.discount_type_value / 100 * voucherDetails.voucherAmount);
                                    break;
                                default:
                                    break;
                            }
                        }

                        const orderDetails = {
                            'order_references': {
                                'delivery_partner_uuid': deliveryPartnerDetails.user_uuid,
                                'customer_uuid': userDetails.user_uuid,
                                'address_uuid': selectedAddress,
                                'merchant_uuid': selectedMerchant.merchant_uuid,
                                'branch_uuid': selectedBranch.branch_uuid,
                            },
                            'voucher_details': {
                                'voucher_uuid': voucherDetails.voucherUuid,
                                'voucher_code': voucherDetails.voucherCode,
                                'voucher_amount': voucherDetails.voucherAmount
                            },
                            'order_values': {
                                'order_total_amount': Math.ceil((totalPrice + selectedDeliveryFee + serviceArea.booking_fee - voucherDetails.voucherAmount - promoDeliveryFee) / 100) * 100,
                                'order_bag_amount': order_total_amount,
                                'order_merchant_due_amount': order_merchant_due_amount,
                                'order_admin_due_amount': order_admin_due_amount + serviceArea.booking_fee,
                                'order_delivery_due_amount': Math.ceil(selectedDeliveryFee / 100) * 100 - promoDeliveryFee,
                            },
                            'delivery_values': {
                                'charge_is_free_delivery': selectedMerchant.is_free_delivery,
                                'charge_distance_meters': selectedDeliveryDistance,
                                'charge_distance_base': Math.ceil((serviceArea.delivery_min_radius / 100) * 1000),
                                'charge_delivery_base_amount': serviceArea.delivery_min_fee,
                                'charge_delivery_excess_rate': serviceArea.delivery_excess_per_km,
                                'charge_delivery_excess_amount': (selectedDeliveryDistance > (serviceArea.delivery_min_radius * 10)) ? Math.ceil(selectedDeliveryFee / 100) * 100 - serviceArea.delivery_min_fee : 0,
                                'charge_delivery_total_amount': Math.ceil(selectedDeliveryFee / 100) * 100 - promoDeliveryFee,
                            },
                            'charge_values': {
                                'charge_commission_rate': selectedMerchant.commission_rate,
                                'charge_commission_amount': order_merchant_commission_amount,
                                'charge_price_rate': selectedMerchant.price_rate,
                                'charge_price_amount': order_merchant_price_rate_amount,
                                'charge_booking_fee_amount': serviceArea.booking_fee,
                                'charge_misc_amount': 0
                            },
                            'order_notes': orderNote,
                            'order_status': 1,
                            'device_type': 'L002',
                            'device_info': JSON.stringify({
                                'appVersion': process.env.REACT_APP_VERSION,
                                'userAgent': window.navigator.userAgent
                            })
                        }

                        const addOrderResult = await AddOrder(orderDetails);
                        if (addOrderResult.data.status === 'success') {
                            bagItems.map(function(item, key) {
                                const values = {
                                    'order_uuid': addOrderResult.data.order_uuid,
                                    'quantity': item.quantity,
                                    'item_uuid': item.item_uuid,
                                    'variation_uuid': item.variation_uuid,
                                    'add_on_required': JSON.stringify(item.add_on_required),
                                    'add_on_optional': JSON.stringify(item.add_on_optional),
                                    'variation_amount': item.variation_amount,
                                    'variation_amount_posted': item.variation_amount_posted,
                                    'add_on_required_amount': item.add_on_required_amount,
                                    'add_on_required_amount_posted': item.add_on_required_amount_posted,
                                    'add_on_optional_amount': item.add_on_optional_amount,
                                    'add_on_optional_amount_posted': item.add_on_optional_amount_posted,
                                    'unit_price': item.unit_total_price,
                                    'unit_price_posted': item.unit_total_price_posted,
                                    'total_price': item.total_price,
                                    'total_price_posted': item.total_price_posted,
                                    'created_at': moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss'),
                                    'updated_at': moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss')
                                }
                                bagItemsPost.push(values);
                                return null;
                            });
                            const addOrderItemsResult = await AddOrderItems({'bag_items': bagItemsPost});
                            const trackingValues = {
                                'order_uuid': addOrderResult.data.order_uuid,
                                'tracking_code': 'G001',
                                'tracking_status': 1
                            }
                            const addTrackingResult = await AddTracking(trackingValues);
                            const messageValues = {
                                'order_uuid': addOrderResult.data.order_uuid,
                                'user_uuid_from': deliveryPartnerDetails.user_uuid,
                                'user_uuid_to': userDetails.user_uuid,
                                'message_type': 'K001',
                                'message_body': 'Hello, this is ' + deliveryPartnerDetails.first_name + ', your assigned delivery partner for the order reference no ' + addOrderResult.data.order_tracking_no + '. I\'m on my way to the merchant to pick up your order. Salamat!',
                                'seen_by_recipient': 0,
                                'message_status': 1
                            }
                            const sendMessageResult = await SendMessage(messageValues);
                            if (addOrderItemsResult.data.status === 'success') {
                                let riderNameGenerated = deliveryPartnerDetails.first_name + ' ' + deliveryPartnerDetails.last_name.charAt(0);
                                riderNameGenerated = riderNameGenerated.toUpperCase();
                                setRiderName(riderNameGenerated);
                                const smsParamsCustomer = {
                                    'mobileNumber': userDetails.mobile_number,
                                    'orderRefNo': addOrderResult.data.order_tracking_no,
                                    'riderName': riderNameGenerated
                                }
                                await SendSms('customer-order-received', smsParamsCustomer);
                                const smsParamsDelivery = {
                                    'mobileNumber': deliveryPartnerDetails.mobile_number,
                                    'orderRefNo': addOrderResult.data.order_tracking_no
                                }
                                await SendSms('delivery-order-assigned', smsParamsDelivery);
                                const smsParamsMerchant = {
                                    'mobileNumber': selectedBranch.mobile_number,
                                    'orderRefNo': addOrderResult.data.order_tracking_no
                                }
                                await SendSms('merchant-order-notification', smsParamsMerchant);
                                setSuccessVisible(true);
                            } else {
                                SubmitState();
                            }
                        } else {
                            SubmitState();
                        }
                    }
                }
                counterSubmit++;
            } else {
                clearInterval(intervalCounterSubmit);
            }
        }, 12000);
    }

    const SubmitState = () => {
        setButtonLabel('Place Order');
        setIsDisabled(false);
        Debounce(NotificationManager.error('Sorry there has been a problem while posting your order. Please try again later.', 'Sorry', 3000), 3000);
    }

    const handleSuccessClose = (event) => {
        setSuccessVisible(false);
        handleEmptyBagItems([]);
        handleSetSelectedBranch(null);
        handleSetSelectedMerchant(null);
        handleSetSelectedVoucher(null);
        handleSetSelectedVoucherItem(null);
        setDrawerRightCheckOut(false);
        setRiderName('');
        history.push('/');
    }

    const handleDirectionsResult = async(response) => {
        if (response !== null) {
            if (response.status !== 'ZERO_RESULTS') {
                setSelectedDistanceDetails(response.routes[0].legs[0]);
                const deliveryFeeDetails = await GetDeliveryFeeActual(response.routes[0].legs[0], serviceArea);
                setSelectedDeliveryDistance(deliveryFeeDetails.actual_distance);
                setSelectedDeliveryFee(Math.ceil(deliveryFeeDetails.actual_fee));
                setDeliveryTimeEstimate(deliveryFeeDetails.travel_time_est);
                handleVoucherSelection(deliveryFeeDetails.actual_fee);
                if (selectedBranch.is_free_delivery === 1 && totalPrice >= selectedMerchant.min_free_delivery) {
                    if (deliveryFeeDetails.actual_fee <= serviceArea.delivery_min_fee) {
                        setPromoDeliveryFee(deliveryFeeDetails.actual_fee);
                    } else {
                        setPromoDeliveryFee(serviceArea.delivery_min_fee);
                    }
                } else {
                    setPromoDeliveryFee(0);
                }
            }
        }
    }

    const handleOpenAddress = (e) => {
        setDrawerRightCheckOut(false);
        setDrawerRightAddressAdd(true);
    }

    useEffect(() => {
        handlePlaceOrderButton();
    }, [selectedDeliveryFee, orderNote]);

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title="Checkout" placement="right" width={screens.md === false ? '100%' : 600} onClose={(event) => handleCloseCheckOut(event) } visible={isVisible}>
            {
                bagItems.length > 0 && selectedBranch && selectedMerchant &&
                <div>
                    <div className="mb-4">
                        <h4>ORDERING FROM</h4>
                        <div className="merchant-details">
                            <Image src={selectedMerchant.merchant_logo_file_url} preview={false} />
                            <div className="item-meta-container">
                                <h3 className="mb-1">{selectedMerchant.merchant_name}</h3>
                                <div>{selectedBranch.branch_name}</div>
                                <div>{GetFoodCategoryLabels(selectedBranch.food_category_types)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <h4>DELIVER TO</h4>
                        {
                            userDetails === null &&
                            <div>
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Notice"
                                    description="You need to log in to your account. If you don't have one, you can register for free."
                                    type="danger"
                                />
                                <Row gutter={[16, 16]} wrap={false}>
                                    <Col span={12}>
                                        <Button className="btn btn-primary btn-border" size={'large'} block onClick={handleOpenLogin}>Login</Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button className="btn btn-info btn-border" size={'large'} block onClick={handleOpenRegister}>Register</Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {
                            userDetails && userAddresses &&
                            <Card className="bm-card">
                                <h3 className="mb-0">{`${userDetails.first_name} ${userDetails.last_name}`}</h3>
                                <div>{userDetails.email_address}</div>
                                <div>{userDetails.mobile_number}</div>
                                <Radio.Group className="my-3" value={selectedAddress} onChange={(e) => handleAddressSelection(e)}>
                                    <Space direction="vertical">
                                        {
                                            userAddresses.map(item => {
                                                return item.address_status === 1 && <Radio value={item.address_uuid}>{`${item.street} ${item.street} ${item.city_mun} ${item.prov_region} ${item.zip_code}`}</Radio>
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                                <Button className="btn btn-primary btn-border" size={'large'} block onClick={(e) => handleOpenAddress(e)}>Add Address</Button>
                                {
                                    isLoaded &&
                                    <GoogleMap id='map'>
                                        {
                                            directionsDestination !== 0 &&
                                            <DirectionsService
                                                options={{
                                                    destination: {lat: directionsDestination.lat, lng: directionsDestination.lng},
                                                    origin: {lat: directionsOrigin.lat, lng: directionsOrigin.lng},
                                                    travelMode: 'DRIVING',
                                                    avoidTolls: true,
                                                }}
                                                callback={handleDirectionsResult}
                                            />
                                        }
                                    </GoogleMap>
                                }
                            </Card>
                        }
                    </div>

                    <div className="mb-4">
                        <h4>BAG ITEMS</h4>
                        <Card className="bm-card">
                            <List
                                className="bag-items-list"
                                itemLayout="horizontal"
                                dataSource={bagItems}
                                renderItem={(item, index) => (
                                    <List.Item
                                        key={item.key}>
                                        <List.Item.Meta
                                            avatar={<ItemCount item={item} index={index} />}
                                            title={<ItemTitle item={item} />}
                                            description={<ItemMeta item={item} />}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </div>

                    <div className="mb-4">
                        <h4>NOTE TO DELIVERY PARTNER</h4>
                        <Card className="bm-card">
                            <Radio.Group value={orderNote} onChange={(e) => setOrderNote(e.target.value)}>
                                <Space direction="vertical">
                                    {
                                        OrderNotes.map(item => {
                                            return <Radio value={item.value}>{item.note}</Radio>
                                        })
                                    }
                                </Space>
                            </Radio.Group>
                        </Card>
                    </div>

                    <div className="mb-4">
                        <div className="bag-items-summary">
                            {
                                selectedDeliveryFee !== null &&
                                <Row gutter={[16, 16]} wrap={false}>
                                    <Col flex={'auto'}>Delivery Fee <span>({NumFormat((selectedDeliveryDistance / 1000), 2)}km)</span></Col>
                                    <Col flex={'90px'} className="text-end">P{NumFormat(Math.ceil(selectedDeliveryFee / 100), 2)}</Col>
                                </Row>
                            }
                            <Row gutter={[16, 16]} wrap={false}>
                                <Col flex={'auto'}>Items</Col>
                                <Col flex={'90px'} className="text-end">P{NumFormat(Math.ceil(totalPrice / 100), 2)}</Col>
                            </Row>
                            <Row gutter={[16, 16]} wrap={false}>
                                <Col flex={'auto'}>Booking Fee</Col>
                                <Col flex={'90px'} className="text-end">P{NumFormat(Math.ceil(serviceArea.booking_fee) / 100, 2)}</Col>
                            </Row>
                            {
                                selectedVoucher && selectedDeliveryFee &&
                                <Row gutter={[16, 16]} wrap={false}>
                                    <Col flex={'auto'}>LESS Voucher</Col>
                                    <Col flex={'90px'} className="text-end">-P{NumFormat((voucherDetails.voucherAmount / 100), 2)}</Col>
                                </Row>
                            }
                            {
                                selectedDeliveryFee && selectedBranch.is_free_delivery === 1 && totalPrice >= selectedMerchant.min_free_delivery &&
                                <Row gutter={[16, 16]} wrap={false}>
                                    <Col flex={'auto'}>LESS Free Delivery Promo</Col>
                                    <Col flex={'90px'} className="text-end">-P{NumFormat((serviceArea.delivery_min_fee / 100), 2)}</Col>
                                </Row>
                            }
                            {
                                selectedDeliveryFee &&
                                <Row gutter={[16, 16]} wrap={false}>
                                    <Col flex={'auto'}>Total</Col>
                                    <Col flex={'90px'} className="text-end">P{NumFormat(Math.ceil((totalPrice / 100) + Math.ceil(selectedDeliveryFee / 100) + (serviceArea.booking_fee / 100) - Math.ceil(voucherDetails.voucherAmount / 100) - Math.ceil(promoDeliveryFee / 100)), 2)}</Col>
                                </Row>
                            }
                        </div>
                        <Button className="btn btn-primary mt-4" size={'large'} block disabled={isDisabled} onClick={(event) => handleSearchDeliveryPartner(event)}>{buttonLabel}</Button>
                    </div>
                </div>
            }
            <Modal className="bm-modal" visible={searchVisible} zIndex={10100} closable={false} footer={null}>
                <div className="text-center">
                    <Spin indicator={<IconLoader />} spinning={true} className="mb-3" />
                    <h1 className="font-color-secondary-x3">Searching</h1>
                    <h3 className="mb-0">Please wait while we search for an available delivery partner. ({timer})</h3>
                </div>
            </Modal>
            <Modal className="bm-modal" visible={successVisible} zIndex={10200} footer={null}>
                <div className="text-center">
                    <h1 className="font-color-primary-x3">Order on the Way</h1>
                    <h3 className="mb-3">Your order has been successfully assigned to {riderName}. We will notify you of the progress of the order soon.</h3>
                    <Button className="btn bm-btn btn-primary" onClick={(event) => handleSuccessClose(event)}>Close</Button>
                </div>
            </Modal>
        </Drawer>
    )
}