import React, { useState } from 'react';
import { Drawer, Alert, Card, List, Grid, Row, Col, Tabs } from 'antd';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { NumFormat } from '../../functions/Math';
import moment from 'moment';
import { useSelector } from 'react-redux';

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

export default function DrawerRightOrders({isVisible, setDrawerRightOrders, setDrawerRightAccount, setDrawerRightOrderDetails, setSelectedOrder}) {
    const pendingOrders = useSelector(state => state.reducer.pendingOrders);
    const pastOrders = useSelector(state => state.reducer.pastOrders);
    const [noOrderLabel, setNoOrderLabel] = useState('Sorry, you don\'t have any pending orders.');
    const screens = useBreakpoint();

    const handleClose = () => {
        setDrawerRightOrders(false);
        setDrawerRightAccount(true);
    }

    const handleTabChange = (activeKey) => {
        if (activeKey === 2) {
            setNoOrderLabel('Sorry, you don\'t have any past orders.');
        } else {
            setNoOrderLabel('Sorry, you don\'t have any pending orders.');
        }
    }

    const IconCircleRight = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons', color: '#0023c4', size: '22px' }}>
                <FiArrowRightCircle />
            </IconContext.Provider>
        )
    }

    const handleOrderDetails = (e, item) => {
        setSelectedOrder(item);
        setDrawerRightOrders(false);
        setDrawerRightOrderDetails(true);
    }

    const OrderItem = (item) => {
        return (
            <Card className="bm-card pointer mb-3" onClick={(e) => handleOrderDetails(e, item)}>
                <Row gutter={[16, 16]} wrap={false}>
                    <Col flex="auto">
                        <h3 className="mb-1 font-color-primary-x3">Order Ref No {item.order_tracking_no}</h3>
                        <h5 className="mb-0 font-color-grey-base-x2">DATE & TIME</h5>
                        <div className="mb-1 font-weight-500">{moment(item.created_at).format('MMM DD, YYYY hh:mm A')}</div>
                        <h5 className="mb-0 font-color-grey-base-x2">AMOUNT</h5>
                        <div className="font-weight-500">P{NumFormat(item.order_total_amount / 100, 2)}</div>
                    </Col>
                    <Col flex="60px" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconCircleRight />
                    </Col>
                </Row>
            </Card>
        )
    }

    const NoOrders = () => {
        return (
            <Alert
                className="bm-alert mt-1 mb-4"
                message="No Orders Found"
                description={noOrderLabel}
                type="danger"
            />
        )
    }

    const PastOrderHeader = () => {
        return (
            <Alert
                className="bm-alert"
                description="Here are our last ten (10) transactions. If you are searching for previous transactions that are not included in this list, please contact support."
                type="info"
            />
        )
    }

    return (
        <Drawer className="bm-drawer" title="Orders" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose(false)} visible={isVisible}>
            <Tabs defaultActiveKey="1" className="pm-tabs" destroyInactiveTabPane={true} onChange={(activeKey) => handleTabChange(activeKey)}>
                <TabPane tab="Pending Orders" key="1" forceRender={true}>
                    {
                        pendingOrders && pendingOrders.length === 0 &&
                        <NoOrders />
                    }
                    {
                        pendingOrders && pendingOrders.length >= 1 &&
                        <List
                            dataSource={pendingOrders}
                            renderItem={(item) => OrderItem(item)}
                            rowKey={(item) => item.order_uuid}
                        />
                    }
                </TabPane>
                <TabPane tab="Past Orders" key="2">
                    {
                        pastOrders && pastOrders.length === 0 &&
                        <NoOrders />
                    }
                    {
                        pastOrders && pastOrders.length >= 1 &&
                        <List
                            dataSource={pastOrders}
                            renderItem={(item) => OrderItem(item)}
                            rowKey={(item) => item.order_uuid}
                            header={<PastOrderHeader />}
                        />
                    }
                </TabPane>
            </Tabs>
        </Drawer>
    )
}