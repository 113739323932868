import React, { useState } from 'react';
import { Drawer, Button, Grid, Input, Form } from 'antd';
import { NumFormat } from '../../functions/Math';
import { FilterObject } from '../../functions/Utilities';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import { GetMaxUses } from '../../services/Vouchers';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVoucher, setSelectedVoucherItem } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightVoucher({isVisible, setDrawerRightVoucher, setDrawerRightBag}) {
    const dispatch = useDispatch();
    const areaVouchers = useSelector(state => state.reducer.areaVouchers);
    const userDetails = useSelector(state => state.reducer.userDetails);
    const selectedBranch = useSelector(state => state.reducer.selectedBranch);
    const selectedMerchant = useSelector(state => state.reducer.selectedMerchant);
    const bagItems = useSelector(state => state.reducer.bagItems);

    const handleSetSelectedVoucher = (voucherDetails) => dispatch(setSelectedVoucher(voucherDetails));
    const handleSetSelectedVoucherItem = (itemDetails) => dispatch(setSelectedVoucherItem(itemDetails));

    // const [voucherCode, setVoucherCode] = useState('');
    const [voucher, setVoucher] = useState(null);
    const totalPrice = bagItems.reduce((n, {total_price_posted}) => n + total_price_posted, 0);

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleOnSubmit = async(values) => {
        const voucherCode = values.voucher_code.toUpperCase().trim();
        if (userDetails === null) {
            Debounce(NotificationManager.error('You need to log in to your account to verify vouchers. If you don\'t have an account, you can register for free.', 'Sorry', 3000), 3000);
            return false;
        }
        const resultVouchers = FilterObject(areaVouchers, voucherCode, 'voucher_code');
        if (resultVouchers.length > 0) {
            setVoucher(resultVouchers[0]);
            checkVoucherCompatibility(resultVouchers[0]);
        } else {
            Debounce(NotificationManager.error('Sorry, we could not find that code. You might have mistyped it (voucher codes have no spaces) or the code you are trying to use has already expired. Please try again.', 'Sorry', 3000), 3000);
            return false;
        }
    }

    const checkVoucherCompatibility = async(resultVoucher) => {
        const maxUsesResult = await GetMaxUses(resultVoucher.voucher_uuid, userDetails.user_uuid);

        // check if merchant is already giving free deliveries
        if (selectedBranch.is_free_delivery === 1 && (resultVoucher.discount_type === 'delivery-fixed' || resultVoucher.discount_type === 'delivery-percentage')) {
            Debounce(NotificationManager.error('You can\'t use delivery vouchers on this merchant. Delivery promotion is already applied by default.', 'Sorry', 3000), 3000);
            return false;
        }

        // check voucher allocation
        if (maxUsesResult.data.data.voucher_orders_uses_count > (resultVoucher.uses_max / 100)) {
            Debounce(NotificationManager.error('Voucher allocation is already used up or consumed. You can contact support for more details.', 'Sorry', 3000), 3000);
            return false;
        }
        
        // check user uses limit
        if (maxUsesResult.data.data.voucher_user_uses_count >= (resultVoucher.uses_max_user / 100)) {
            let timeLabel = '';
            ((resultVoucher.uses_max_user / 100) > 1) ? timeLabel = 'times' : timeLabel = 'time';
            Debounce(NotificationManager.error('You have already used this voucher ' + (resultVoucher.uses_max_user / 100) + ' ' + timeLabel + ', which is the limit for its usage.', 'Sorry', 3000), 3000);
            return false;
        }

        // check order amount minimum
        if ((totalPrice / 100) < (resultVoucher.min_order_amount / 100)) {
            Debounce(NotificationManager.error('The voucher requires a minimum total order amount of P' + NumFormat((resultVoucher.min_order_amount / 100), 2) + '. Please add more items and try again.', 'Sorry', 3000), 3000);
            return false;
        }

        // check merchant promo
        if (resultVoucher.merchant_uuid === null) {
            handleVoucherApply(null);
        } else {
            if (resultVoucher.merchant_uuid === selectedMerchant.merchant_uuid) {
                if (resultVoucher.branch_uuid === null && resultVoucher.item_uuid === null) {
                    handleVoucherApply(null);
                } else {
                    if (resultVoucher.branch_uuid === null && resultVoucher.item_uuid !== null) {
                        const resultItem = FilterObject(bagItems, resultVoucher.item_uuid, 'item_uuid');
                        if (resultItem.length > 0) {
                            handleVoucherApply(resultItem[0]);
                        } else {
                            Debounce(NotificationManager.error('There are no items in your bag to apply this voucher. Please review the voucher mechanics.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    } else if (resultVoucher.branch_uuid !== null && resultVoucher.item_uuid === null) {
                        if (resultVoucher.branch_uuid === selectedBranch.branch_uuid) {
                            handleVoucherApply(null);
                        } else {
                            Debounce(NotificationManager.error('Voucher is not valid for this branch.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    } else if (resultVoucher.branch_uuid !== null && resultVoucher.item_uuid !== null) {
                        const resultItem = FilterObject(bagItems, resultVoucher.item_uuid, 'item_uuid');
                        if (resultVoucher.branch_uuid === selectedBranch.branch_uuid && resultItem.length > 0) {
                            handleVoucherApply(resultItem[0])
                        } else {
                            Debounce(NotificationManager.error('This voucher is not valid for the branch and the items you selected. Please review the voucher mechanics.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    }
                }
            } else {
                Debounce(NotificationManager.error('Voucher is not valid for this merchant.', 'Sorry', 3000), 3000);
                return false;
            }
        }
    }

    const handleVoucherApply = (item) => {
        handleSetSelectedVoucher(voucher);
        handleSetSelectedVoucherItem(item);
        setDrawerRightVoucher(false);
        setTimeout(() => {
            setDrawerRightBag(true);
        }, 1000);
    }

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title="Vouchers" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => setDrawerRightVoucher(false)} visible={isVisible}>
            <Form
                layout="vertical"
                form={form}
                onFinish={handleOnSubmit}>
                <Form.Item name={'voucher_code'} label="Voucher Code" type={'text'} rules={[{ required: true, message: 'Voucher Code is required.' }]}>
                    <Input placeholder="voucher code" className="bm-input" />
                </Form.Item>
                <Button className="btn btn-primary mt-2" size={'large'} block htmlType={'submit'}>Submit</Button>
            </Form>
        </Drawer>
    )
}