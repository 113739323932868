import React from 'react';
import { Drawer, Button, Grid, Input, Form } from 'antd';
import { UserUpdate } from '../../services/Users';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';

import { useSelector } from 'react-redux';

const { useBreakpoint } = Grid;

export default function DrawerRightProfilePassword({isVisible, setDrawerRightProfilePassword, setDrawerRightProfileView}) {
    const userDetails = useSelector(state => state.reducer.userDetails);

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleForm = async(values) => {
        if (values.password !== values.confirm_password) {
            Debounce(NotificationManager.error('Passwords are different. Please check and try again.', 'Sorry', 1000), 1000);
            return false;
        }
        const postData = {
            password: values.password
        }
        const result = await UserUpdate(postData, userDetails.user_uuid);
        if (result.data.status === 'success') {
            Debounce(NotificationManager.success('Password has been updated successfully.', 'Notice', 3000), 3000);
            handleClose();
        } else {
            Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleClose = () => {
        setDrawerRightProfilePassword(false);
        setDrawerRightProfileView(true);
    }

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title={'Profile Update'} placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose()} visible={isVisible}>
            <Form layout="vertical" form={form} onFinish={handleForm}>
                <Form.Item name={'password'} label="Password" type={'password'} rules={[{ required: true, message: 'Password is required.' }]}>
                    <Input.Password placeholder="password" className="bm-input" defaultValue={''} />
                </Form.Item>
                <Form.Item name={'confirm_password'} label="Confirm Password" type={'password'} rules={[{ required: true, message: 'Password is required.' }]}>
                    <Input.Password placeholder="confirm password" className="bm-input" defaultValue={''} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="btn btn-primary mt-4" size="large" htmlType="submit" block>Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}