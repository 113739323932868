import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducer from './Reducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['userDetails', 'userAddresses', 'currentLocation', 'serviceAreas', 'serviceArea', 'selectedBranch', 'selectedMerchant', 'selectedVoucher', 'selectedVoucherItem', 'bagItems', 'pendingOrders', 'pastOrders']
};

const rootReducer = combineReducers({
    reducer: persistReducer(persistConfig, Reducer)
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);