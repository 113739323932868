import React from 'react';
import { Row, Col, Grid } from 'antd';

const { useBreakpoint } = Grid;

export default function SectionWrapper({children, styles={}}) {
    const screens = useBreakpoint();
    return (
        <div className={screens.sm === false ? 'bm-container py-2' : 'bm-container py-4'} style={styles}>
            <Row gutter={16}>
                <Col xs={{span: 24}} lg={{span: 18, push: 3}}>
                    {children}
                </Col>
            </Row>
        </div>
    )
}