export const OrderNotes = [
    {
        "id": "1",
        "value": "K001",
        "note": "Contact me if any of the items are unavailable."
    },
    {
        "id": "2",
        "value": "K002",
        "note": "Proceed and just remove unavailable items."
    }
]
