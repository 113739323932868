import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Input, Image, Tag, Modal, Radio, Checkbox, Space, Form, Grid } from 'antd';
import PageLayout from '../../components/layout/PageLayout';
import SectionWrapper from '../../components/layout/SectionWrapper';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveBranch, setServiceAreas, setNearServiceArea, addBagItem, setSelectedBranch, setSelectedMerchant } from '../../store/Action';
import { FormatItemsList, GetFoodCategoryLabels, FilterObject, Debounce, MergeArrayObjects, ConvertToSlug } from '../../functions/Utilities';
import { GetDistance, NumFormat, GetPriceDisplay, GetAddToBagFormat } from '../../functions/Math';
import { GetServiceAreaDetails } from '../../services/ServiceAreas';
import { GetMerchantDetails, GetMerchantActiveItems } from '../../services/Merchants';
import { GetBranchDetails } from '../../services/MerchantBranches';
import { GetActiveMenu, GetLastMenu } from '../../services/Menus';
import { GetMenuCategories } from '../../services/MenuCategories';
import { GetFileDetails } from '../../services/Files';
import ReactGA from 'react-ga';

import { IconContext } from 'react-icons';
import { FiArrowLeft, FiArrowRight, FiChevronUp } from 'react-icons/fi';
import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';

const { useBreakpoint } = Grid;

export default function MerchantsView() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const {uuid}  = useParams();
    let navRef = useRef();
    let Element = Scroll.Element;
    let ScrollLink = Scroll.Link;
    let scroll = Scroll.animateScroll;
    const screens = useBreakpoint();

    const [branchDetails, setBranchDetails] = useState(null);
    const [merchantDetails, setMerchantDetails] = useState(null);
    const [menuDetails, setMenuDetails] = useState(null);
    const [isMenuDisabled, setIsMenuDisabled] = useState(false);
    const [menuCategories, setMenuCategories] = useState(null);
    const [merchantItems, setMerchantItems] = useState(null);
    const [itemsFormatted, setItemsFormatted] = useState(null);
    const [branchBanner, setBranchBanner] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemVariation, setSelectedItemVariation] = useState(null);
    const [itemAddOnRequired, setItemAddOnRequired] = useState([]);
    const [itemAddOnOptional, setItemAddOnOptional] = useState([]);
    const [itemRequiredCount, setItemRequiredCount] = useState(0);
    const [itemQty, setItemQty] = useState(1);

    const serviceArea = useSelector(state => state.reducer.serviceArea);
    const currentLocation = useSelector(state => state.reducer.currentLocation);
    const bagItems = useSelector(state => state.reducer.bagItems);
    const handleSetServiceArea = (serviceArea) => dispatch(setNearServiceArea(serviceArea));
    const handleSetSelectedBranch = (selectedBranch) => dispatch(setSelectedBranch(selectedBranch));
    const handleSetSelectedMerchant = (selectedMerchant) => dispatch(setSelectedMerchant(selectedMerchant));
    const handleSetActiveBranch = (branch) => dispatch(setActiveBranch(branch));
    const handleAddBagItem = (item) => dispatch(addBagItem(item));

    const getPageData = async() => {
        setIsLoading(true);
        
        let menu_uuid;
        const resultBranchDetails = await GetBranchDetails(uuid);
        const resultActiveMenu = await GetActiveMenu(uuid);
        const resultLastMenu = await GetLastMenu(uuid);
        const resultMerchantActiveItems = await GetMerchantActiveItems(uuid);
        const resultServiceArea = await GetServiceAreaDetails(resultBranchDetails.data.data.service_area_uuid);

        if (resultActiveMenu.data.data.length > 0) {
            setMenuDetails(resultActiveMenu.data.data[0]);
            menu_uuid = resultActiveMenu.data.data[0].menu_uuid;
        } else {
            setIsMenuDisabled(true);
            setMenuDetails(resultLastMenu.data.data[0]);
            menu_uuid = resultLastMenu.data.data[0].menu_uuid;
        }

        const values = {
            'lat1': currentLocation.lat,
            'lng1': currentLocation.lng,
            'lat2': resultBranchDetails.data.data.map_lat,
            'lng2': resultBranchDetails.data.data.map_lng
        }
        resultBranchDetails.data.data.distance = GetDistance(values);
        setBranchDetails(resultBranchDetails.data.data);
        setMerchantItems(resultMerchantActiveItems.data.data);
        
        const resultMenuCategories = await GetMenuCategories(menu_uuid);
        const resultMerchantDetails = await GetMerchantDetails(resultBranchDetails.data.data.merchant_uuid);
        const resultFileDetails = await GetFileDetails(resultBranchDetails.data.data.listing_photo_file_uuid);

        setMenuCategories(resultMenuCategories.data.data);
        setMerchantDetails(resultMerchantDetails.data.data);
        setItemsFormatted(FormatItemsList(resultMenuCategories.data.data, resultMerchantActiveItems.data.data, resultMerchantDetails.data.data));
        setBranchBanner(resultFileDetails.data.data);
        
        if (serviceArea.service_area_uuid !== resultServiceArea.data.data.service_area_uuid) {
            handleSetServiceArea(resultServiceArea.data.data);
        }

        if (bagItems.length === 0) {
            handleSetSelectedBranch(resultBranchDetails.data.data);
            handleSetSelectedMerchant(resultMerchantDetails.data.data);
        }

        handleSetActiveBranch(resultBranchDetails.data.data);

        setIsLoading(false);
        setIsLoaded(true);
    }

    const handleClickItem = (item) => {
        setSelectedItem(item);
        setIsModalVisible(true);
    }

    const handleItemCancel = () => {
        setSelectedItem(null);
        setIsModalVisible(false);
        setSelectedItemVariation(null);
        setItemAddOnRequired([]);
        setItemAddOnOptional([]);
        setItemRequiredCount(0);
        setItemQty(1);
    }

    const ItemVariationsViewComponent = () => {
        let variationsData = [];
        merchantItems.variations.forEach((item, index) => {
            if (item.item_uuid === selectedItem.item_uuid) {
                variationsData.push(item);
            }
        });
        return (
            <div className="item-section-container pm-border-bottom secondary">
                <Radio.Group onChange={handleSelectedItemVariation} value={selectedItemVariation}>
                    <Space direction="vertical">
                    {
                        variationsData.map(variationItem => {
                            return <Radio value={variationItem.variation_uuid}>
                                {variationItem.variation_name}<br />
                                P{GetPriceDisplay(variationItem.variation_price, merchantDetails.price_rate)}
                            </Radio>
                        })
                    }
                    </Space>
                </Radio.Group>
            </div>
        )
    }

    const ItemVariationAddOnsViewComponent = () => {
        setItemRequiredCount(0);
        let addOnsData = [];
        let count = 0;
        let resultVariationAddOns = FilterObject(merchantItems.variation_add_ons, selectedItemVariation, 'variation_uuid');
        resultVariationAddOns.map(item => {
            if (item.variation_add_on_status !== '0') {
                const result = FilterObject(merchantItems.add_on_groups, item.add_on_group_uuid, 'add_on_group_uuid');
                let headingTitle;
                (result[0].is_required === 1) ? headingTitle = 'REQUIRED (select one)' : headingTitle = 'OPTIONAL';
                item.add_on_group_heading = headingTitle;
                item.add_on_group_name = result[0].add_on_group_name;
                item.add_on_group_status = result[0].add_on_group_status;
                item.is_multiple = result[0].is_multiple;
                item.is_required = result[0].is_required;
                // add on options
                const resultOptions = FilterObject(merchantItems.add_ons, result[0].add_on_group_uuid, 'add_on_group_uuid');
                item.add_on_group_options = resultOptions;
                addOnsData.push(item);
                if (result[0].is_required === 1) {
                    count++;
                };
            }
            return null;
        });
        setItemRequiredCount(count);
        return (
            <div>
                {
                    addOnsData.map((item, key) => {
                        return (
                            <div className="item-section-container pm-border-bottom secondary">
                                <h3 className="mb-0">{item.add_on_group_name}</h3>
                                <div className="font-color-grey-base-x3 font-size-xs font-weight-500">{item.add_on_group_heading}</div>
                                {
                                    item.add_on_group_options.length > 0 && item.is_multiple === 0 &&
                                    <Form.Item name={`required-${key}`}>
                                        <Radio.Group onChange={(e) => handleSelectRequiredAddOn(e, key)}>
                                            <Space direction="vertical" className="mt-2">
                                                {
                                                    item.add_on_group_options.map(itemOptionRequired => {
                                                        return (
                                                            <Radio
                                                                value={itemOptionRequired.add_on_uuid}>
                                                                    {itemOptionRequired.add_on_name}<br />
                                                                    add P{GetPriceDisplay(itemOptionRequired.add_on_price, merchantDetails.price_rate)}
                                                            </Radio>
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                }
                                {
                                    item.add_on_group_options.length > 0 && item.is_multiple === 1 &&
                                    <Form.Item  name={`optional-${key - itemRequiredCount}`}>
                                        <Checkbox.Group onChange={(values) => handleSelectOptionalAddOn(values, key - itemRequiredCount)}>
                                            <Space direction="vertical" className="mt-2">
                                                {
                                                    item.add_on_group_options.map(itemOptionOptional => {
                                                        return (
                                                            <Checkbox
                                                                value={itemOptionOptional.add_on_uuid}>
                                                                    {itemOptionOptional.add_on_name}<br />
                                                                    add P{GetPriceDisplay(itemOptionOptional.add_on_price, merchantDetails.price_rate)}
                                                            </Checkbox>
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Checkbox.Group>
                                    </Form.Item>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const handleSelectedItemVariation = (event) => {
        setSelectedItemVariation(event.target.value);
    }

    const handleSelectRequiredAddOn = (event, key) => {
        let tempRequired = itemAddOnRequired;
        tempRequired[`required-${key}`] = event.target.value;
        setItemAddOnRequired(tempRequired);
    }

    const handleSelectOptionalAddOn = (values, key) => {
        let tempOptional = itemAddOnOptional;
        tempOptional[`optional-${key}`] = values;
        setItemAddOnOptional(tempOptional);
    }

    const handleQtyDecrement = () => {
        let count = itemQty - 1;
        if (count <= 1) {
            setItemQty(1);
        } else {
            setItemQty(count);
        }
    }
    
    const handleQtyIncrement = () => {
        let count = itemQty + 1;
        if (count >= 10) {
            setItemQty(10);
        } else {
            setItemQty(count);
        }
    }

    const handleAddToBag = () => {
        setIsLoading(true);
        if (selectedItemVariation === null) {
            setIsLoading(false);
            Debounce(NotificationManager.error('Please select a variation.', 'Notice', 1000), 1000);
        } else {
            if (itemRequiredCount === Object.keys(itemAddOnRequired).length) {
                // check if of same branch
                if (bagItems.length > 0) {
                    if (bagItems[0].branch_uuid === branchDetails.branch_uuid) {
                        addToBagItem();
                    } else {
                        setIsLoading(false);
                        Debounce(NotificationManager.error('You are not allowed to add items from a different merchant.', 'Notice', 1000), 1000);
                    }
                } else {
                    addToBagItem();
                }
            } else {
                setIsLoading(false);
                Debounce(NotificationManager.error('Select all required options.', 'Notice', 1000), 1000);
            }
        }
    }

    const addToBagItem = () => {
        let itemAddOnRequiredArray = MergeArrayObjects(itemAddOnRequired);
        let itemAddOnOptionalArray = MergeArrayObjects(itemAddOnOptional);
        const itemFormatted = GetAddToBagFormat(branchDetails, merchantItems, merchantDetails.price_rate, selectedItem, itemQty, selectedItemVariation, itemAddOnRequiredArray, itemAddOnOptionalArray);
        handleAddBagItem(itemFormatted);
        Debounce(NotificationManager.success('Item added to bag.', 'Notice', 1000), 1000);
        setIsLoading(false);
        handleItemCancel();
        scroll.scrollToTop();
    }

    useEffect(() => {
        getPageData();
        ReactGA.pageview(window.location.pathname);
    }, []);

    const handleNav = (direction) => {
        if (direction === 'left') {
            navRef.current.scrollLeft -= 200;
        } else {
            navRef.current.scrollLeft += 200;
        }
    }

    const IconLeft = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiArrowLeft style={{ fontSize: '24px'}} />
            </IconContext.Provider>
        )
    }

    const IconRight = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiArrowRight style={{ fontSize: '24px'}} />
            </IconContext.Provider>
        )
    }

    const IconBackToTop = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiChevronUp style={{ fontSize: '40px', margin: 0, stroke: 'white'}} />
            </IconContext.Provider>
        )
    }

    const categoryStyles = {
        background: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    };

    const CategoryItem = ({item}) => {
        let photoUrl;
        item.item_photo_file_url === null ? photoUrl = 'https://bilimoko.net/drive/assets/img/default-item-photo.png' : photoUrl = item.item_photo_file_url;
        return (
            <Col xs={{span: 24}} md={{span: 12}}>
                <div className={
                    screens.sm === false ?
                        isMenuDisabled === true ? 'category-item-container disabled mobile' : 'category-item-container mobile' :
                        isMenuDisabled === true ? 'category-item-container disabled' : 'category-item-container'
                    }
                    onClick={() => isMenuDisabled === false ? handleClickItem(item) : false}>
                    {isMenuDisabled === true ? <div className='overlay'><Tag className='bm-tag bm-tag-secondary'>NOT AVAILABLE</Tag></div> : <></>}
                    <Image src={photoUrl} preview={false} />
                    <div className="item-meta-container">
                        <h3>{item.item_name}</h3>
                        <div className="item-price">P{NumFormat(Math.ceil(item.starts_at_price / 100), 2)}</div>
                    </div>
                </div>
            </Col>
        )
    }

    return (
        <PageLayout loading={isLoading}>
            {
                (isLoaded === true) &&
                <div>
                    <div className={screens.sm === false ? 'merchant-header mobile' : 'merchant-header'} style={{ backgroundImage: `url(${branchBanner.file_url})` }}>
                        <div className="merchant-header-details-wrapper">
                            {
                                isMenuDisabled === true &&
                                <Tag className='bm-tag bm-tag-secondary mb-3 mx-auto'>MERCHANT IS CLOSED</Tag>
                            }
                            <div className="merchant-header-details">
                                <h2>{branchDetails.branch_name}</h2>
                                <div className="merchant-header-meta">{NumFormat(branchDetails.distance, 2)}km</div>
                                <div className="merchant-header-meta">{GetFoodCategoryLabels(branchDetails.food_category_types)}</div>
                            </div>
                        </div>
                    </div>
                    <SectionWrapper styles={categoryStyles}>
                        <div className={screens.sm === false ? 'category-items-wrapper mobile' : 'category-items-wrapper'}>
                            <Button className="btn btn-direction btn-direction-left" icon={<IconLeft />} onClick={() => handleNav('left')}></Button>
                            <div className="category-items" ref={navRef}>
                                {
                                    menuCategories.map(item => {
                                        return <ScrollLink to={item.branch_category_uuid} spy={true} smooth={true} duration={500} offset={screens.sm === false ? -70 : -100}>{item.category_name}</ScrollLink>
                                    })
                                }
                            </div>
                            <Button className="btn btn-direction btn-direction-right" icon={<IconRight />} onClick={() => handleNav('right')}></Button>
                        </div>
                    </SectionWrapper>
                    <SectionWrapper>
                        {
                            itemsFormatted.map(item => {
                                return (
                                    <Element className="merchant-category-wrapper" id={item.branch_category_uuid}>
                                        <h2>{item.category_name}</h2>
                                        <Row align={'top'} gutter={[30, {xs: 20, sm: 20, md: 40}]}>
                                            {
                                                item.data.map(categoryItem => {
                                                    return <CategoryItem item={categoryItem} />
                                                })
                                            }
                                        </Row>
                                    </Element>
                                )
                            })
                        }
                        <div className="back-to-top">
                            <Button className="btn btn-back-to-top"icon={<IconBackToTop />} onClick={() => scroll.scrollToTop()}></Button>
                        </div>
                    </SectionWrapper>
                </div>
            }
            {
                selectedItem &&
                <Modal className="bm-modal item-selection-modal" visible={isModalVisible} onCancel={handleItemCancel}
                    footer={[
                        <Button className="btn btn-default" onClick={handleItemCancel}>Close</Button>,
                        // <Button className="btn btn-primary" type="primary" onClick={handleChangeArea}>Change Location</Button>
                    ]}>
                    <div className="item-photo">
                        {
                            (selectedItem.item_photo_file_url === '' || selectedItem.item_photo_file_url === null) ?
                            <Image src='https://bilimoko.net/drive/assets/img/default-item-photo.png' preview={false} /> :
                            <Image src={selectedItem.item_photo_file_url} preview={false} />
                        }
                    </div>
                    {/* item details */}
                    <div className="item-section-container pb-0">
                        <h2 className="mb-0">{selectedItem.item_name}</h2>
                        <div className="font-color-grey-base-x3">{selectedItem.item_description}</div>
                    </div>
                    {/* variations */}
                    <Form
                        initialValues={[...itemAddOnRequired, ...itemAddOnOptional]}>
                        <ItemVariationsViewComponent />
                        {
                            selectedItemVariation &&
                            <ItemVariationAddOnsViewComponent />
                        }
                    </Form>
                    <div className="selection-wrapper">
                        <Row gutter={16}>
                            <Col xs={12}>
                                <div className="item-count-wrapper">
                                    <Button className="btn btn-default btn-subtract" size="large" onClick={handleQtyDecrement}>-</Button>
                                    <Input className="bm-input" value={itemQty} readOnly={true} />
                                    <Button className="btn btn-default btn-add" size="large" onClick={handleQtyIncrement}>+</Button>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Button className="btn btn-primary" size="large" block onClick={handleAddToBag}>Add to Bag</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }
            {
                branchDetails && branchBanner &&
                <Helmet prioritizeSeoTags>
                    <title>{`${branchDetails.branch_name} Delivery in ${branchDetails.address_city_mun} | bilimoko food delivery`}</title>
                    <meta name="description" content={`Order ${branchDetails.branch_name} located in ${branchDetails.address_street} ${branchDetails.address_city_mun} ${branchDetails.address_prov_region}. We deliver fast and quick. Food delivery to your home or office in ${branchDetails.address_city_mun} ${branchDetails.address_prov_region} via bilimoko.`} />
                    <meta property="og:title" content={`${branchDetails.branch_name} delivery in ${branchDetails.address_city_mun} | bilimoko food delivery`} />
                    <meta property="og:url" content={`https://app.bilimoko.net/merchants/view/${branchDetails.branch_uuid}/${ConvertToSlug(branchDetails.branch_name)}`} />
                    <meta property="og:type" content="article" />
                    <meta property="og:description" content={`Order ${branchDetails.branch_name} located in ${branchDetails.address_street} ${branchDetails.address_city_mun} ${branchDetails.address_prov_region}. We deliver fast and quick. Food delivery to your home or office in ${branchDetails.address_city_mun} ${branchDetails.address_prov_region} via bilimoko.`} />
                    <meta property="og:image" content={branchBanner.file_url} />
                    <meta property="twitter:title" content={`${branchDetails.branch_name} delivery in ${branchDetails.address_city_mun} | bilimoko food delivery`} />
                    <meta property="twitter:description" content={`Order ${branchDetails.branch_name} located in ${branchDetails.address_street} ${branchDetails.address_city_mun} ${branchDetails.address_prov_region}. We deliver fast and quick. Food delivery to your home or office in ${branchDetails.address_city_mun} ${branchDetails.address_prov_region} via bilimoko.`} />
                    <meta property="twitter:image" content={branchBanner.file_url} />
                    <meta property="twitter:card" content="summary_large_image" />
                </Helmet>
            }
        </PageLayout>
    )
}