import React from 'react';
import PageLayout from '../../components/layout/PageLayout';
import SectionWrapper from '../../components/layout/SectionWrapper';

export default function Static404() {
    return (
        <PageLayout>
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">404</h1>
                    <h2 className="section-subtitle">Sorry, there is nothing here.</h2>
                </div>
            </SectionWrapper>
        </PageLayout>
    )
}