import React, { useState, useEffect } from 'react';
import { Drawer, Alert, Button, List, Avatar, Grid, Row, Col, Input, Card, Form } from 'antd';
import { FiTrash2 } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { NumFormat, GetDeliveryFee } from '../../functions/Math';
import { FilterObject, ConvertToSlug } from '../../functions/Utilities';
import { GetMaxUses } from '../../services/Vouchers';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import { useHistory, generatePath, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedVoucher, setSelectedVoucherItem, emptyBagItems, removeBagItem, updateBagItem } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightBag({isVisible, setDrawerRightBag, setDrawerRightCheckOut}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const userDetails = useSelector(state => state.reducer.userDetails);
    const areaVouchers = useSelector(state => state.reducer.areaVouchers);
    const currentLocation = useSelector(state => state.reducer.currentLocation);
    const bagItems = useSelector(state => state.reducer.bagItems);
    const selectedBranch = useSelector(state => state.reducer.selectedBranch);
    const selectedMerchant = useSelector(state => state.reducer.selectedMerchant);
    const activeBranch = useSelector(state => state.reducer.activeBranch);
    const serviceArea = useSelector(state => state.reducer.serviceArea);
    const selectedVoucher = useSelector(state => state.reducer.selectedVoucher);
    const handleUpdateBagItem = (values) => dispatch(updateBagItem(values));
    const handleEmptyBag = (items) => dispatch(emptyBagItems(items));
    const handleRemoveBagItem = (key) => dispatch(removeBagItem(key));
    const handleSetSelectedVoucher = (voucherDetails) => dispatch(setSelectedVoucher(voucherDetails));
    const handleSetSelectedVoucherItem = (itemDetails) => dispatch(setSelectedVoucherItem(itemDetails));
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const screens = useBreakpoint();
    const [form] = Form.useForm();
    
    const handleOnClose = (event) => {
        if (bagItems.length > 0) {
            if (activeBranch === null) {
                handleReload();
            } else if (bagItems[0].branch_uuid === activeBranch.branch_uuid && location.pathname !== '/') {
                setDrawerRightBag(false);
            } else {
                handleReload();
            }
        } else {
            setDrawerRightBag(false);
        }
    }

    const handleReload = (event) => {
        const path = generatePath('/merchants/view/:uuid/:slug', {
            uuid: bagItems[0].branch_uuid,
            slug: ConvertToSlug(bagItems[0].branch_name)
        });
        history.push({ pathname: path });
        window.location.reload(false);
    }

    const handleCheckOut = (event) => {
        setDrawerRightBag(false);
        setDrawerRightCheckOut(true);
    }

    const ComputeDeliveryFee = () => {
        if (bagItems.length > 0 && selectedBranch) {
            const deliveryFeeTemp = GetDeliveryFee(selectedBranch, currentLocation, serviceArea);
            setDeliveryFee(deliveryFeeTemp);
            const totalPriceItems = bagItems.reduce((n, {total_price_posted}) => n + total_price_posted, 0);
            setTotalPrice(totalPriceItems);
        } else {
            setDeliveryFee(0);
            setTotalPrice(0);
        }
    }

    useEffect(() => {
        ComputeDeliveryFee();
    }, [bagItems]);

    const ItemAvatar = ({item}) => {
        let photo_url = item.item_photo_file_url === null ? 'https://bilimoko.net/drive/assets/img/default-item-photo.png' : item.item_photo_file_url;
        return (
            <Avatar src={photo_url} shape={'square'} size={80} />
        )
    }

    const ItemTitle = ({item}) => {
        return (
            <Row gutter={[16, 16]} wrap={false}>
                <Col flex={'auto'}>{`(${item.quantity}x) ${item.item_name} ${item.variation_name}`}</Col>
                <Col flex={'100px'} className="text-end">P{NumFormat((item.variation_amount_posted * item.quantity) / 100, 2)}</Col>
            </Row>
        )
    }

    const ItemMeta = ({item}) => {
        let requiredLabelArray = [];
        let optionalLabelArray = [];
        item.add_on_required.map(itemRequired => {
            return requiredLabelArray.push(`(${item.quantity}x) ${itemRequired.add_on_name}`);
        });
        item.add_on_optional.map(itemOptional => {
            return optionalLabelArray.push(`(${item.quantity}x) ${itemOptional.add_on_name}`);
        });
        return (
            <div>
                {
                    requiredLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{requiredLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_required_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
                {
                    optionalLabelArray.length > 0 &&
                    <Row gutter={[16, 16]} wrap={false} className="font-color-grey-base-x5">
                        <Col flex={'auto'}>{optionalLabelArray.join(', ')}</Col>
                        <Col flex={'100px'} className="text-end">P{NumFormat((item.add_on_optional_amount_posted) / 100, 2)}</Col>
                    </Row>
                }
                <div className="mt-3">
                    <div className="selection-wrapper-bag">
                        <Row gutter={[16, 16]} wrap={false}>
                            <Col xs={{span: 12}} sm={{span: 8}}>
                                <div className="item-count-wrapper">
                                    <Button className="btn btn-default btn-subtract"onClick={(e) => handleQtyDecrement(e, item)}>-</Button>
                                    <Input className="bm-input" value={item.quantity} readOnly={true} />
                                    <Button className="btn btn-default btn-add" onClick={(e) => handleQtyIncrement(e, item)}>+</Button>
                                </div>
                            </Col>
                            <Col xs={{span: 12}} sm={{span: 8}}>
                                <Button type="danger" className="btn btn-danger" onClick={(e) => handleRemoveItem(e, item.key)}><IconTrash /> Remove</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

    const handleQtyDecrement = (e, item) => {
        const index = bagItems.indexOf(item);
        const quantity = bagItems[index].quantity - 1;
        if (quantity > 0) {
            const bagUpdateData = {
                index: index,
                values : {
                    quantity: quantity,
                    add_on_optional_amount: bagItems[index].add_on_optional_amount_unit * quantity,
                    add_on_optional_amount_posted : bagItems[index].add_on_optional_amount_unit_posted * quantity,
                    add_on_required_amount : bagItems[index].add_on_required_amount_unit * quantity,
                    add_on_required_amount_posted : bagItems[index].add_on_required_amount_unit_posted * quantity,
                    total_price : bagItems[index].unit_total_price * quantity,
                    total_price_posted : bagItems[index].unit_total_price_posted * quantity
                }
            }
            handleUpdateBagItem(bagUpdateData);
            handleSetSelectedVoucher(null);
            handleSetSelectedVoucherItem(null);
        } else {
            Debounce(NotificationManager.error('Not allowed. Click REMOVE if you want to remove the item.', 'Sorry', 1000), 1000);
        }
    }

    const handleQtyIncrement = (e, item) => {
        const index = bagItems.indexOf(item);
        const quantity = bagItems[index].quantity + 1;
        const bagUpdateData = {
            index: index,
            values : {
                quantity: quantity,
                add_on_optional_amount: bagItems[index].add_on_optional_amount_unit * quantity,
                add_on_optional_amount_posted : bagItems[index].add_on_optional_amount_unit_posted * quantity,
                add_on_required_amount : bagItems[index].add_on_required_amount_unit * quantity,
                add_on_required_amount_posted : bagItems[index].add_on_required_amount_unit_posted * quantity,
                total_price : bagItems[index].unit_total_price * quantity,
                total_price_posted : bagItems[index].unit_total_price_posted * quantity
            }
        }
        handleUpdateBagItem(bagUpdateData);
    }

    const handleRemoveItem = (event, itemKey) => {
        const result = handleRemoveBagItem(itemKey);
        if (result) {
            Debounce(NotificationManager.success('Item removed from bag.', 'Notice', 1000), 1000);
            handleSetSelectedVoucher(null);
            handleSetSelectedVoucherItem(null);
        }
    }

    const IconTrash = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiTrash2 />
            </IconContext.Provider>
        )
    }

    const handleVoucherSubmit = (values) => {
        let voucherCode = values.voucher_code.toUpperCase().trim();
        if (userDetails === null) {
            Debounce(NotificationManager.error('You need to log in to your account to verify vouchers. If you don\'t have an account, you can register for free.', 'Sorry', 3000), 3000);
            return false;
        }
        let resultVouchers = FilterObject(areaVouchers, voucherCode, 'voucher_code');
        if (resultVouchers.length > 0) {
            checkVoucherCompatibility(resultVouchers[0]);
        } else {
            Debounce(NotificationManager.error('Sorry, we could not find that code. You might have mistyped it (voucher codes have no spaces) or the code you are trying to use has already expired. Please try again.', 'Sorry', 3000), 3000);
            return false;
        }
    }

    const checkVoucherCompatibility = async(resultVoucher) => {
        const maxUsesResult = await GetMaxUses(resultVoucher.voucher_uuid, userDetails.user_uuid);

        // check if merchant is already giving free deliveries
        if (selectedBranch.is_free_delivery === 1 && (resultVoucher.discount_type === 'delivery-fixed' || resultVoucher.discount_type === 'delivery-percentage')) {
            Debounce(NotificationManager.error('You can\'t use delivery vouchers on this merchant. Delivery promotion is already applied by default.', 'Sorry', 3000), 3000);
            return false;
        }

        // check voucher allocation
        if (maxUsesResult.data.data.voucher_orders_uses_count > (resultVoucher.uses_max / 100)) {
            Debounce(NotificationManager.error('Voucher allocation is already used up or consumed. You can contact support for more details.', 'Sorry', 3000), 3000);
            return false;
        }
        
        // check user uses limit
        if (maxUsesResult.data.data.voucher_user_uses_count >= (resultVoucher.uses_max_user / 100)) {
            let timeLabel = '';
            ((resultVoucher.uses_max_user / 100) > 1) ? timeLabel = 'times' : timeLabel = 'time';
            Debounce(NotificationManager.error('You have already used this voucher ' + (resultVoucher.uses_max_user / 100) + ' ' + timeLabel + ', which is the limit for its usage.', 'Sorry', 3000), 3000);
            return false;
        }

        // check order amount minimum
        if ((totalPrice / 100) < (resultVoucher.min_order_amount / 100)) {
            Debounce(NotificationManager.error('The voucher requires a minimum total order amount of P' + NumFormat((resultVoucher.min_order_amount / 100), 2) + '. Please add more items and try again.', 'Sorry', 3000), 3000);
            return false;
        }

        // check merchant promo
        if (resultVoucher.merchant_uuid === null) {
            handleVoucherApply(resultVoucher, null);
        } else {
            if (resultVoucher.merchant_uuid === selectedMerchant.merchant_uuid) {
                if (resultVoucher.branch_uuid === null && resultVoucher.item_uuid === null) {
                    handleVoucherApply(resultVoucher, null);
                } else {
                    if (resultVoucher.branch_uuid === null && resultVoucher.item_uuid !== null) {
                        const resultItem = FilterObject(bagItems, resultVoucher.item_uuid, 'item_uuid');
                        if (resultItem.length > 0) {
                            handleVoucherApply(resultVoucher, resultItem[0]);
                        } else {
                            Debounce(NotificationManager.error('There are no items in your bag to apply this voucher. Please review the voucher mechanics.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    } else if (resultVoucher.branch_uuid !== null && resultVoucher.item_uuid === null) {
                        if (resultVoucher.branch_uuid === selectedBranch.branch_uuid) {
                            handleVoucherApply(resultVoucher, null);
                        } else {
                            Debounce(NotificationManager.error('Voucher is not valid for this branch.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    } else if (resultVoucher.branch_uuid !== null && resultVoucher.item_uuid !== null) {
                        const resultItem = FilterObject(bagItems, resultVoucher.item_uuid, 'item_uuid');
                        if (resultVoucher.branch_uuid === selectedBranch.branch_uuid && resultItem.length > 0) {
                            handleVoucherApply(resultVoucher, resultItem[0])
                        } else {
                            Debounce(NotificationManager.error('This voucher is not valid for the branch and the items you selected. Please review the voucher mechanics.', 'Sorry', 3000), 3000);
                            return false;
                        }
                    }
                }
            } else {
                Debounce(NotificationManager.error('Voucher is not valid for this merchant.', 'Sorry', 3000), 3000);
                return false;
            }
        }
    }

    const VoucherTicket = ({voucher}) => {
        return (
            <Card className="bm-card card-voucher my-5">
                <Row>
                    <Col flex={'100px'} className="font-color-info-x5 font-size-md">Voucher</Col>
                    <Col flex={'auto'} className="text-end font-color-info-x5 font-weight-700 font-size-md">{voucher.voucher_code}</Col>
                </Row>
                <Row>
                    <Col className="font-color-info-x5">Discounts specified in this Voucher Code will be applied when you checkout.</Col>
                </Row>
            </Card>
        )
    }

    const handleVoucherApply = (voucher, item) => {
        handleSetSelectedVoucher(voucher);
        handleSetSelectedVoucherItem(item);
    }

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title="Bag" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => setDrawerRightBag(false)} visible={isVisible}>
            {
                bagItems.length > 0 &&
                <div className="pb-5">
                    <List
                        className="bag-items-wrapper mb-3"
                        itemLayout="horizontal"
                        dataSource={bagItems}
                        renderItem={item => (
                            <List.Item
                                key={item.key}>
                                <List.Item.Meta
                                    avatar={<ItemAvatar item={item} />}
                                    title={<ItemTitle item={item} />}
                                    description={<ItemMeta item={item} />}
                                />
                            </List.Item>
                        )}
                    />
                    <Row gutter={[16, 16]} wrap={false} className="mb-2">
                        <Col flex={'auto'}>
                            <div className="font-size-md font-weight-500">Delivery Fee</div>
                            <div className="font-size-xs font-color-grey-base-x3">This fee is just an estimate based on your current location. Final fee will be computed based on the address you will select upon checkout.</div>
                        </Col>
                        <Col flex={'100px'} className="text-end font-size-md font-weight-500">P{NumFormat(Math.ceil(deliveryFee / 100), 2)}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false} className="mb-2">
                        <Col flex={'auto'}>
                            <div className="font-size-md font-weight-500">Items</div>
                        </Col>
                        <Col flex={'100px'} className="text-end font-size-md font-weight-500">P{NumFormat(Math.ceil(totalPrice / 100), 2)}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false} className="mb-2">
                        <Col flex={'auto'}>
                            <div className="font-size-md font-weight-500">Booking Fee</div>
                        </Col>
                        <Col flex={'100px'} className="text-end font-size-md font-weight-500">P{NumFormat(Math.ceil(serviceArea.booking_fee) / 100, 2)}</Col>
                    </Row>
                    <Row gutter={[16, 16]} wrap={false}>
                        <Col flex={'auto'}>
                            <div className="font-size-md font-weight-700">Total</div>
                        </Col>
                        <Col flex={'100px'} className="text-end font-size-md font-weight-700">P{NumFormat(Math.ceil((totalPrice / 100) + (serviceArea.booking_fee / 100) + (deliveryFee / 100)), 2)}</Col>
                    </Row>
                    {
                        selectedVoucher === null &&
                        <Card className="bm-card card-voucher my-5">
                            <h3>Add Voucher Code</h3>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={handleVoucherSubmit}>
                                <Form.Item name={'voucher_code'} label="Voucher Code" type={'text'} rules={[{ required: true, message: 'Voucher Code is required.' }]}>
                                    <Input placeholder="voucher code" className="bm-input" allowClear={true} />
                                </Form.Item>
                                <Button className="btn btn-primary mt-2" size={'large'} block htmlType={'submit'}>Verify Voucher</Button>
                            </Form>
                        </Card>
                    }
                    {
                        selectedVoucher &&
                        <VoucherTicket voucher={selectedVoucher} />
                    }
                    <div className="mt-4">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Button className="btn btn-primary btn-border" size={'large'} onClick={(event) => handleOnClose(event)} block>Add More Items</Button>
                            </Col>
                            <Col span={12}>
                                <Button className="btn btn-primary" size={'large'} onClick={(event) => handleCheckOut(event)} block>Checkout</Button>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
            }
            {
                bagItems.length === 0 &&
                <Alert
                    className="bm-alert mt-1 mb-4"
                    message="No Items"
                    description="You don't have any items in your bag. Try adding one."
                    type="info"
                />
            }
        </Drawer>
    )
}