import React, { useState, useEffect } from 'react';
import { PageHeader, Row, Col, Button, Input, List, Dropdown, Modal, Badge, Typography, Grid } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import BMBranding from '../../components/elements/BMBranding';
import { IconContext } from 'react-icons';
import { FiMapPin, FiShoppingBag, FiUser } from 'react-icons/fi';
import { Debounce, FilterObject } from '../../functions/Utilities';
import { GetDistance } from '../../functions/Math';
import { NotificationManager } from 'react-notifications';
import { GetPendingOrders, GetPastOrders } from '../../services/Orders';
import { GetMerchantDetails, GetMerchantActiveItems } from '../../services/Merchants';
import { UserUpdate } from '../../services/Users';
import { GetBranchDetails } from '../../services/MerchantBranches';
import { GetAddressDetails } from '../../services/Addresses';
import { GetOrderItems } from '../../services/OrderItems';
import { GetUserDetails } from '../../services/Users';
import moment from 'moment';
import DrawerRightBag from '../elements/DrawerRightBag';
import DrawerRightCheckOut from '../elements/DrawerRightCheckOut';
import DrawerRightLogin from '../elements/DrawerRightLogin';
import DrawerRightRegister from '../elements/DrawerRightRegister';
import DrawerRightAccount from '../elements/DrawerRightAccount';
import DrawerRightVoucher from '../elements/DrawerRightVoucher';
import DrawerRightOrders from '../elements/DrawerRightOrders';
import DrawerRightOrderDetails from '../elements/DrawerRightOrderDetails';
import DrawerRightAddresses from '../elements/DrawerRightAddresses';
import DrawerRightAddressAdd from '../elements/DrawerRightAddressAdd';
import DrawerRightProfileView from '../elements/DrawerRightProfileView';
import DrawerRightProfileUpdate from '../elements/DrawerRightProfileUpdate';
import DrawerRightProfilePassword from '../elements/DrawerRightProfilePassword';
import DrawerRightVerify from '../elements/DrawerRightVerify';
import DrawerRightForgot from '../elements/DrawerRightForgot';

import { GetServiceAreas } from '../../services/ServiceAreas';

import { useSelector, useDispatch } from 'react-redux';
import { setAuthDetails, setCurrentLocation, setServiceAreas, setNearServiceArea, setSelectedBranch, setSelectedMerchant, emptyBagItems, setSelectedVoucher, setSelectedVoucherItem, setPendingOrders, setPastOrders } from '../../store/Action';

const { useBreakpoint } = Grid;
const { Search } = Input;

export default function PageLayoutHeader() {
    const { Text } = Typography;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [modalNotice, setModalNotice] = useState(false);
    const [drawerRightBag, setDrawerRightBag] = useState(false);
    const [drawerRightCheckOut, setDrawerRightCheckOut] = useState(false);
    const [drawerRightLogin, setDrawerRightLogin] = useState(false);
    const [drawerRightRegister, setDrawerRightRegister] = useState(false);
    const [drawerRightAccount, setDrawerRightAccount] = useState(false);
    const [drawerRightVoucher, setDrawerRightVoucher] = useState(false);
    const [drawerRightOrders, setDrawerRightOrders] = useState(false);
    const [drawerRightOrderDetails, setDrawerRightOrderDetails] = useState(false);
    const [drawerRightAddresses, setDrawerRightAddresses] = useState(false);
    const [drawerRightAddressAdd, setDrawerRightAddressAdd] = useState(false);
    const [drawerRightProfileView, setDrawerRightProfileView] = useState(false);
    const [drawerRightProfileUpdate, setDrawerRightProfileUpdate] = useState(false);
    const [drawerRightProfilePassword, setDrawerRightProfilePassword] = useState(false);
    const [drawerRightVerify, setDrawerRightVerify] = useState(false);
    const [drawerRightForgot, setDrawerRightForgot] = useState(false);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

    const serviceArea = useSelector(state => state.reducer.serviceArea);
    const serviceAreas = useSelector(state => state.reducer.serviceAreas);
    const currentLocation = useSelector(state => state.reducer.currentLocation);
    const bagItems = useSelector(state => state.reducer.bagItems);
    const userDetails = useSelector(state => state.reducer.userDetails);
    const pendingOrders = useSelector(state => state.reducer.pendingOrders);
    const handleSetCurrentLocation = (currentLocation) => dispatch(setCurrentLocation(currentLocation));
    const handleSetNearServiceArea = (serviceArea) => dispatch(setNearServiceArea(serviceArea));
    const handleSetServiceAreas = (serviceAreas) => dispatch(setServiceAreas(serviceAreas));
    const handleEmptyBagItems = (value) => dispatch(emptyBagItems(value));
    const handleSetSelectedBranch = (branchDetails) => dispatch(setSelectedBranch(branchDetails));
    const handleSetSelectedMerchant = (merchantDetails) => dispatch(setSelectedMerchant(merchantDetails));
    const handleSetSelectedVoucher = (voucher) => dispatch(setSelectedVoucher(voucher));
    const handleSetSelectedVoucherItem = (voucherItem) => dispatch(setSelectedVoucherItem(voucherItem));
    const handleSetPendingOrders = (orders) => dispatch(setPendingOrders(orders));
    const handleSetPastOrders = (orders) => dispatch(setPastOrders(orders));
    const handleSetAuthDetails = (userDetails) => dispatch(setAuthDetails(userDetails));

    const screens = useBreakpoint();

    const initLocationServices = () => {
        // handleSetCurrentLocation(null);
        // handleSetNearServiceArea(null);
        // handleSetServiceAreas(null);
        if (userDetails) {
            if (userDetails.session_created_at) {
                // time diffence to restart session
                const sessionDifferenceBase = 151200; // seconds
                const sessionDifference = moment().diff(moment(userDetails.session_created_at), 'seconds');
                if (sessionDifference > sessionDifferenceBase) {
                    handleLogoutUser();
                }
            } else {
                handleLogoutUser();
            }
        }
        
        if (currentLocation === null) {
            navigator.geolocation.getCurrentPosition(result => {
                const values = {
                    lat: result.coords.latitude,
                    lng: result.coords.longitude 
                }
                handleSetCurrentLocation(values);
                initGetServiceAreas(values);
            }, error => {
                Debounce(NotificationManager.error(error.message, 'Notice', 1000), 1000);
                initGetServiceAreas(null);
            }, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 60000
            });
        } else {
            initGetServiceAreas(currentLocation);
            if (userDetails) {
                userLastSeen(userDetails.user_uuid);
            }
        }
    }

    const initGetServiceAreas = async(userPosition) => {
        if (serviceAreas === null && serviceArea === null) {
            const resultServiceAreas = await GetServiceAreas();
            handleSetServiceAreas(resultServiceAreas.data.data);
            handleSetNearServiceArea(resultServiceAreas.data.data[0]);
            handleGetCurrentLocation(resultServiceAreas.data.data, userPosition);
        } else {
            handleChangeArea();
        }
    }

    const handleGetCurrentLocation = (serviceAreas, userPosition) => {
        if (userPosition === null) {
            const values = {
                lat: serviceAreas[0].area_lat,
                lng: serviceAreas[0].area_lng,
            }
            handleSetCurrentLocation(values);
        }
        currentLocation &&
        serviceAreas.map((value, key) => {
            const values = {
                'lat1': currentLocation.lat,
                'lng1': currentLocation.lng,
                'lat2': value.area_lat,
                'lng2': value.area_lng
            }
            serviceAreas[key].user_distance = GetDistance(values);
            return null;
        });
        serviceAreas.sort(function(a, b) {
            return a.user_distance - b.user_distance;
        });
        handleSetServiceAreas(serviceAreas);
        handleSetNearServiceArea(serviceAreas[0]);
        setTimeout(function() {
            window.location.reload();
        }, 500);
    }

    const handleChangeNotice = (event, item) => {
        if (item.service_area_uuid !== serviceAreas[0].service_area_uuid) {
            handleEmptyBagItems([]);
            handleSetSelectedBranch(null);
            handleSetSelectedMerchant(null);
            handleSetSelectedVoucher(null);
            handleSetSelectedVoucherItem(null);
            handleSetNearServiceArea(item);
        }
    }

    const handleChangeArea = () => {
        if (serviceArea) {
            const result = FilterObject(serviceAreas, serviceArea.service_area_uuid, 'service_area_uuid');
            const serviceAreaReordered = [];
            serviceAreaReordered.push(result[0]);
            serviceAreas.map(item => {
                if (item.service_area_uuid !== result[0].service_area_uuid) {
                    serviceAreaReordered.push(item);
                }
                return null;
            });
            handleSetServiceAreas(serviceAreaReordered);
        }
    }

    const handleCancel = () => {
        setModalNotice(false);
    }

    const handleGetUserOrders = async() => {
        if (userDetails !== null) {
            const pendingResult = await GetPendingOrders(userDetails.user_uuid);
            const pastResult = await GetPastOrders(userDetails.user_uuid, 10);
            handleSetPendingOrders(pendingResult.data.data);
            handleSetPastOrders(pastResult.data.data);
        }
    }

    const getOrderDetails = async() => {
        if (selectedOrder !== null) {
            const merchantDetailsResult = await GetMerchantDetails(selectedOrder.merchant_uuid);
            const branchDetailsResult = await GetBranchDetails(selectedOrder.branch_uuid);
            const addressDetailsResult = await GetAddressDetails(selectedOrder.address_uuid);
            const orderItemsResult = await GetOrderItems(selectedOrder.order_uuid);
            const merchantItemsResult = await GetMerchantActiveItems(selectedOrder.branch_uuid);
            const deliveryPartnerResult = await GetUserDetails(selectedOrder.delivery_partner_uuid);
            setSelectedOrderDetails({
                merchantDetails: merchantDetailsResult.data.data,
                branchDetails: branchDetailsResult.data.data,
                addressDetails: addressDetailsResult.data.data,
                orderItems: orderItemsResult.data.data,
                merchantItems: merchantItemsResult.data.data,
                deliveryPartnerDetails: deliveryPartnerResult.data.data
            });
        }
    }

    const userLastSeen = async(user_uuid) => {
        if (user_uuid !== '') {
            const locationValues = {
                last_seen_lat: (currentLocation) ? currentLocation.lat : null,
                last_seen_lng: (currentLocation) ? currentLocation.lng : null,
            }
            await UserUpdate(locationValues, user_uuid);
        }
    }

    const handleLogoutUser = () => {
        handleSetAuthDetails(null);
        handleSetSelectedVoucher(null);
        handleSetSelectedVoucherItem(null);
        handleSetPendingOrders(null);
        handleSetPastOrders(null);
    }

    useEffect(() => {
        initLocationServices();
    }, []);

    useEffect(() => {
        handleChangeArea();
    }, [serviceArea]);

    useEffect(() => {
        handleGetUserOrders();
    }, [userDetails]);

    useEffect(() => {
        getOrderDetails();
    }, [selectedOrder]);

    const IconMapPin = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiMapPin />
            </IconContext.Provider>
        )
    }
    const IconBag = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiShoppingBag />
            </IconContext.Provider>
        )
    }
    
    const IconUser = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiUser />
            </IconContext.Provider>
        )
    }

    const serviceAreaMenu = (serviceAreas) => {
        return (
            <List
                className="service-area-dropdown"
                dataSource={serviceAreas}
                renderItem={(item, key) => areaMenuItem(item, key)}
            />
        )
    }

    const areaMenuItem = (item, key) => {
        return (
            <List.Item>
                <Button onClick={(event) => handleChangeNotice(event, item)} className={key === 0 ? 'active' : null}>{item.service_area_name}</Button>
            </List.Item>
        )
    }

    const handleOpenBagDrawer = () => {
        setDrawerRightBag(true);
    }

    const handleOpenLoginDrawer = () => {
        setDrawerRightLogin(true);
    }

    const handleOpenAccountDrawer = () => {
        setDrawerRightAccount(true);
    }

    const handleSearch = (value) => {
        if (value !== '') {
            history.push('/search', {searchString: value});
        }
    }

    return (
        <PageHeader className="bm-page-header">
            <Row wrap={true} align="middle" gutter={[0, 24]}>
                <Col xs={{span: 10, order: 1}} md={{span: 7, order: 1}} lg={{span: 6}}>
                    <BMBranding dimensions={{width: 100, marginRight: 100}} />
                </Col>
                <Col flex="auto" xs={{order: 3}} md={{span: 10, order: 2}} lg={{span: 12}}>
                    <Row wrap={false}>
                        <Col>
                            {
                                serviceAreas &&
                                <Dropdown overlay={(location.pathname === '/') ? serviceAreaMenu(serviceAreas) : <></>} trigger={['click']}>
                                    <Button
                                        className="btn btn-secondary me-2"
                                        size={screens.sm === false ? 'default' : 'large'}
                                        icon={<IconMapPin />}
                                        onClick={(event) => (location.pathname === '/') ? null : history.push('/')}>
                                        <Text ellipsis={true} style={{ maxWidth: '100px'}} className="font-color-white">
                                            {serviceAreas[0].service_area_name}
                                        </Text>
                                    </Button>
                                </Dropdown>
                            }
                        </Col>
                        <Col flex="auto">
                            <Search className="bm-input" placeholder="search merchants" enterButton="Search" size={screens.sm === false ? 'default' : 'large'} onSearch={(value, event) => handleSearch(value)} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={{span: 14, order: 2}} md={{span: 7, order: 3}} lg={{span: 6}} className="text-end">
                    <Badge count={bagItems.length} showZero={true} className="me-3 bm-badge-secondary">
                        <Button className="btn btn-primary" size={screens.sm === false ? 'default' : 'large'} icon={<IconBag />} onClick={handleOpenBagDrawer}>Bag</Button>
                    </Badge>
                    {
                        userDetails === null &&
                        <Button className="btn btn-primary" size={screens.sm === false ? 'default' : 'large'} icon={<IconUser />} onClick={handleOpenLoginDrawer}>Log In</Button>
                    }
                    {
                        pendingOrders && userDetails !== null &&
                        <Badge count={pendingOrders.length} className="me-3 bm-badge-secondary">
                            <Button className="btn btn-info" size={screens.sm === false ? 'default' : 'large'} icon={<IconUser />} onClick={handleOpenAccountDrawer}>{userDetails.first_name}</Button>
                        </Badge>
                    }
                    
                </Col>
            </Row>
            <Modal className="bm-modal" title="Notice" visible={modalNotice}
                footer={[
                    // <Button className="btn btn-default" onClick={handleCancel}>Cancel</Button>,
                    <Button className="btn btn-primary" type="primary" onClick={handleCancel}>Ok</Button>
                ]}>
                <p>We have removed all items in your cart.</p>
            </Modal>
            <DrawerRightBag isVisible={drawerRightBag} setDrawerRightBag={setDrawerRightBag} setDrawerRightCheckOut={setDrawerRightCheckOut} setDrawerRightVoucher={setDrawerRightVoucher} />
            <DrawerRightCheckOut isVisible={drawerRightCheckOut} setDrawerRightCheckOut={setDrawerRightCheckOut} setDrawerRightBag={setDrawerRightBag} setDrawerRightLogin={setDrawerRightLogin} setDrawerRightRegister={setDrawerRightRegister} setDrawerRightAddressAdd={setDrawerRightAddressAdd} />
            <DrawerRightLogin isVisible={drawerRightLogin} setDrawerRightLogin={setDrawerRightLogin} setDrawerRightRegister={setDrawerRightRegister} setDrawerRightForgot={setDrawerRightForgot} />
            <DrawerRightRegister isVisible={drawerRightRegister} setDrawerRightRegister={setDrawerRightRegister} setDrawerRightLogin={setDrawerRightLogin} />
            <DrawerRightAccount isVisible={drawerRightAccount} setDrawerRightAccount={setDrawerRightAccount} setDrawerRightOrders={setDrawerRightOrders} setDrawerRightAddresses={setDrawerRightAddresses} setDrawerRightProfileView={setDrawerRightProfileView} setDrawerRightVerify={setDrawerRightVerify} />
            <DrawerRightVoucher isVisible={drawerRightVoucher} setDrawerRightVoucher={setDrawerRightVoucher} setDrawerRightBag={setDrawerRightBag} />
            <DrawerRightOrders isVisible={drawerRightOrders} setDrawerRightOrders={setDrawerRightOrders} setDrawerRightAccount={setDrawerRightAccount} setDrawerRightOrderDetails={setDrawerRightOrderDetails} setSelectedOrder={setSelectedOrder} />
            <DrawerRightOrderDetails isVisible={drawerRightOrderDetails} setDrawerRightOrderDetails={setDrawerRightOrderDetails} setDrawerRightOrders={setDrawerRightOrders} selectedOrder={selectedOrder} selectedOrderDetails={selectedOrderDetails} setSelectedOrder={setSelectedOrder} setSelectedOrderDetails={setSelectedOrderDetails} />
            <DrawerRightAddresses isVisible={drawerRightAddresses} setDrawerRightAddresses={setDrawerRightAddresses} setDrawerRightAccount={setDrawerRightAccount} setDrawerRightAddressAdd={setDrawerRightAddressAdd} />
            <DrawerRightAddressAdd isVisible={drawerRightAddressAdd} setDrawerRightAddressAdd={setDrawerRightAddressAdd} setDrawerRightAddresses={setDrawerRightAddresses} />
            <DrawerRightProfileView isVisible={drawerRightProfileView} setDrawerRightProfileView={setDrawerRightProfileView} setDrawerRightAccount={setDrawerRightAccount} setDrawerRightProfileUpdate={setDrawerRightProfileUpdate} setDrawerRightProfilePassword={setDrawerRightProfilePassword} />
            <DrawerRightProfileUpdate isVisible={drawerRightProfileUpdate} setDrawerRightProfileUpdate={setDrawerRightProfileUpdate} setDrawerRightProfileView={setDrawerRightProfileView} />
            <DrawerRightProfilePassword isVisible={drawerRightProfilePassword} setDrawerRightProfilePassword={setDrawerRightProfilePassword} setDrawerRightProfileView={setDrawerRightProfileView} />
            <DrawerRightVerify isVisible={drawerRightVerify} setDrawerRightVerify={setDrawerRightVerify} setDrawerRightAccount={setDrawerRightAccount} />
            <DrawerRightForgot isVisible={drawerRightForgot} setDrawerRightForgot={setDrawerRightForgot} setDrawerRightLogin={setDrawerRightLogin} />
        </PageHeader>
    )
}