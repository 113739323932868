import React from 'react';
import { Drawer, Button, List, Grid, Card, Row, Col } from 'antd';
import { FiTrash2 } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { UpdateAddress } from '../../services/Addresses';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';

import { useSelector, useDispatch } from 'react-redux';
import { setUserAddresses } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightAddresses({isVisible, setDrawerRightAddresses, setDrawerRightAccount, setDrawerRightAddressAdd}) {
    const dispatch = useDispatch();
    const userAddresses = useSelector(state => state.reducer.userAddresses);
    const handleSetUserAddresses = (addresses) => dispatch(setUserAddresses(addresses));
    const screens = useBreakpoint();

    const handleClose = (e) => {
        setDrawerRightAddresses(false);
        setDrawerRightAccount(true);
    }

    const handleAddAddress = (e) => {
        setDrawerRightAddresses(false);
        setDrawerRightAddressAdd(true);
    }

    const IconTrash = () => {
        return (
            <IconContext.Provider value={{ className: 'bm-react-icons' }}>
                <FiTrash2 />
            </IconContext.Provider>
        )
    }

    const AddressItem = (item) => {
        return (
            <Card className="bm-card mb-3">
                <Row gutter={[16, 16]} wrap={false}>
                    <Col flex={'auto'}>{`${item.street} ${item.city_mun} ${item.prov_region} ${item.zip_code}`}</Col>
                    <Col flex={'100px'}><Button type="danger" className="btn btn-danger" onClick={(e) => handleRemoveAddress(e, item.address_uuid)}><IconTrash /> Remove</Button></Col>
                </Row>
            </Card>
        )
    }

    const handleRemoveAddress = async(e, address_uuid) => {
        const postData = {
            address_status: 1
        }
        const updateResult = await UpdateAddress(postData, address_uuid);
        if (updateResult.data.status === 'success') {
            Debounce(NotificationManager.success('Address now removed from your account.', 'Notice', 1000), 1000);
            let tempUserAddresses = userAddresses;
            tempUserAddresses = tempUserAddresses.filter(address => address.address_uuid !== address_uuid);
            handleSetUserAddresses(tempUserAddresses);
        } else {
            Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    return (
        <Drawer className="bm-drawer" title="Addresses" placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose(e)} visible={isVisible}>
            <List
                dataSource={userAddresses}
                renderItem={(item) => AddressItem(item)}
            />
            <Button className="btn btn-primary mt-3" size={'large'} onClick={(e) => handleAddAddress(e)} block>Add Address</Button>
        </Drawer>
    )
}