import React from 'react';
import { Row, Col, Image, Space, Grid } from 'antd';
import { IconContext } from 'react-icons';
import { FiInstagram, FiTwitter, FiFacebook } from 'react-icons/fi';
import moment from 'moment';
import BMLogoLight from '../../assets/img/bilimoko-logo-white.png';
import AppStoreAndroid from '../../assets/img/app-store-android-white.png';
import AppStoreApple from '../../assets/img/app-store-apple-white.png';

export default function PageLayoutFooter() {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const IconFB = () => {
        return (
            <IconContext.Provider value={{ className: '' }}>
                <FiFacebook />
            </IconContext.Provider>
        )
    }

    const IconIG = () => {
        return (
            <IconContext.Provider value={{ className: '' }}>
                <FiInstagram />
            </IconContext.Provider>
        )
    }

    const IconTW = () => {
        return (
            <IconContext.Provider value={{ className: '' }}>
                <FiTwitter />
            </IconContext.Provider>
        )
    }
    return (
        <div className="bm-container bm-footer">
            <Row gutter={[16, 16]} className="mb-4 px-3">
                <Col xs={{span: 24, order: 2}} lg={{span: 14, order: 1}} wrap={true} className={screens.sm === false ? 'text-center mt-4' : ''}>
                    <Image src={BMLogoLight} preview={false} width={100} />
                    <div className="mt-4">
                        <h3 className="text-white">Also available in</h3>
                        <Space>
                            <a href={'https://bit.ly/373QNQ1'} target="_blank" rel="noreferrer"><Image src={AppStoreAndroid} preview={false} width={140} /></a>
                            <a href={'https://apple.co/3BANT3p'} target="_blank" rel="noreferrer"><Image src={AppStoreApple} preview={false} width={140} /></a>
                        </Space>
                    </div>
                </Col>
                <Col xs={{span: 24, order: 1}} lg={{span: 10, order: 2}}>
                    <Row gutter={[16, 16]}>
                        <Col xs={{span: 12}}>
                            <Space direction="vertical">
                                <a href="https://bilimoko.net/web/about" target="_blank" rel="noreferrer" className="footer-link">About</a>
                                <a href="https://cloud.bilimoko.net/register/merchant" target="_blank" rel="noreferrer" className="footer-link">Add your Restaurant</a>
                                <a href="https://bilimoko.net/blog" target="_blank" rel="noreferrer" className="footer-link">Blog</a>
                            </Space>
                        </Col>
                        <Col xs={{span: 12}}>
                            <Space direction="vertical">
                                <a href="https://bilimoko.net/web/privacy-policy" target="_blank" rel="noreferrer" className="footer-link">Privacy Policy</a>
                                <a href="https://bilimoko.net/web/terms-and-conditions" target="_blank" rel="noreferrer" className="footer-link">Terms & Conditions</a>
                                <a href="https://bilimoko.net/web/support" target="_blank" rel="noreferrer" className="footer-link">Support</a>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mt-5">
                        <Col xs={{span: 24}} className={screens.sm === false ? 'text-center' : 'text-end'}>
                            <Space className="footer-links-social-icons">
                                <a href="https://fb.com/bilimokoAppPH" target="_blank" rel="noreferrer" className="footer-link-social"><IconFB /></a>
                                <a href="https://instagr.am/bilimokoAppPH" target="_blank" rel="noreferrer" className="footer-link-social"><IconIG /></a>
                                <a href="https://twitter.com/bilimokoAppPH" target="_blank" rel="noreferrer" className="footer-link-social"><IconTW /></a>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-4">
                <Col span={24}>
                    <div className={screens.sm === false ? 'notice-section pt-3 text-center' : 'notice-section pt-3 text-end'}>© {moment().format('Y')} Falcom Technologies Inc.</div>
                </Col>
            </Row>
        </div>
    )
}