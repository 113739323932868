export const SendSms = async(type, params) => {
    let messageBody = '';
    const codes = [1113, 1129, 1147, 1186, 1192, 1198, 1218, 1289, 1294, 1301, 1350, 1408, 1417, 1476, 1478, 1545, 1569, 1575, 1610, 1612, 1647, 1700, 1713, 1748, 1754, 1765, 1890, 1909, 1928, 1960, 1970, 2060, 2070, 2115, 2147, 2180, 2185, 2216, 2243, 2319, 2381, 2403, 2457, 2487, 2491, 2494, 2524, 2563, 2577, 2587, 2709, 2757, 2767, 2779, 2798, 2835, 2846, 2880, 2881, 2913, 2920, 2942, 2945, 2955, 2989, 3061, 3168, 3184, 3249, 3366, 3391, 3467, 3666, 3749, 3803, 3824, 3826, 3963, 4040, 4114, 4227, 4256, 4258, 4261, 4343, 4403, 4445, 4456, 4460, 4531, 4583, 4664, 4686, 4785, 5031, 5192, 5221, 5236, 5336, 5490, 5558, 5581, 5604, 5661, 5677, 5689, 5709, 5740, 5743, 5782, 5804, 5843, 5888, 5898, 5906, 5936, 5978, 5999, 6004, 6070, 6169, 6185, 6257, 6259, 6265, 6267, 6284, 6357, 6387, 6410, 6436, 6440, 6467, 6507, 6589, 6628, 6659, 6922, 6940, 6996, 7050, 7168, 7177, 7218, 7224, 7253, 7420, 7494, 7547, 7555, 7579, 7624, 7708, 7759, 7797, 7860, 7864, 7903, 7988, 7994, 8147, 8240, 8264, 8266, 8344, 8421, 8513, 8546, 8555, 8633, 8652, 8672, 8697, 8716, 8741, 8816, 8847, 8932, 8962, 9038, 9050, 9209, 9257, 9313, 9337, 9422, 9493, 9507, 9555, 9598, 9605, 9642, 9695, 9698, 9704, 9745, 9763, 9801, 9871, 9952];
    const rand = Math.floor(Math.random() * 200);
    switch (type) {
        case 'register':
            messageBody = `Hello ${params.firstName}! Welcome to BILIMOKO. You are now registered and your food cravings are just a few taps away. Your mobile verification code is ${codes[rand]}. You will need to enter this once you login to your account.`;
            break;
        case 'resend-verification-codes':
            messageBody = `Hello ${params.firstName}! Your generated mobile verification code is ${codes[rand]}.`;
            break;
        case 'customer-order-received':
            messageBody = `Thank you for ordering your food via BILIMOKO. Your order is now assigned to ${params.riderName}. We will send you an update in a few minutes. Order Ref No ${params.orderRefNo}`;
            break;
        case 'delivery-order-assigned':
            messageBody = `You have been assigned to an order with the Ref No ${params.orderRefNo}. Check your BILIMOKO Delivery Partners App and proceed to the merchant immediately.`;
            break;
        case 'merchant-order-notification':
            messageBody = `An order with Ref No ${params.orderRefNo} has been posted. Please check your BILIMOKO Portal for the order details and prepare the items. Our delivery partner is on his/her way. Thank you!`;
            break;
        default:
            break;
    }
    SendSMSNotification(params.mobileNumber, messageBody);
}

const SendSMSNotification = async(mobileNumber, messageBody) => {
    let toNumber = encodeURI(mobileNumber);
    let message = messageBody;
    let username = encodeURI('tarokins');
    let password = encodeURI('adFkRwHPtCJ7TS39');
    let agree = encodeURI('YES');
    let msgType = 1;
    let sendid = encodeURI('BILIMOKO');
    fetch(`https://www.isms.com.my/isms_send.php?un=${username}&pwd=${password}&dstno=${toNumber}&msg=${message}&sendid=${sendid}&type=${msgType}&agreedterm=${agree}`);
};