import axios from 'axios';

export const GetActiveMenu = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus/branch/' + uuid + '/active');
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetLastMenu = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/menus/branch/' + uuid + '/last');
        return result;
    } catch (error) {
        throw error;
    }
}