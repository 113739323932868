import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Image, Card, Alert, List } from 'antd';
import PageLayout from '../../components/layout/PageLayout';
import SectionWrapper from '../../components/layout/SectionWrapper';
import { useSelector } from 'react-redux';
import { GetFoodCategoryLabels, FilterObject, ConvertToSlug } from '../../functions/Utilities';
import { GetDistance, NumFormat,  } from '../../functions/Math';
import { SearchBranches } from '../../services/MerchantBranches';
import { FoodCategoryTypes } from '../../data/FoodCategoryTypes';
import LogoSmile from '../../assets/img/bilimoko-smile.png';

import Icon from '@ant-design/icons';
import Motorcycle from '../../assets/icons/Motorcycle';
import ReactGA from 'react-ga';

export default function CategoriesBranches() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const {code}  = useParams();
    const [categoryBranches, setCategoryBranches] = useState([]);

    const selectedCategory = FilterObject(FoodCategoryTypes, code, 'code');
    const serviceArea = useSelector(state => state.reducer.serviceArea);
    const currentLocation = useSelector(state => state.reducer.currentLocation);

    const handleSearch = async() => {
        setIsLoading(true);
        let searchParams = {
            food_category_type_code: code,
            service_area_uuid: serviceArea.service_area_uuid,
        }
        let searchResult = await SearchBranches(searchParams);
        searchResult.data.data.map((value, key) => {
            const locationPoints = {
                'lat1': currentLocation ? currentLocation.lat : serviceArea.area_lat,
                'lng1': currentLocation ? currentLocation.lng : serviceArea.area_lng,
                'lat2': value.map_lat,
                'lng2': value.map_lng
            }
            searchResult.data.data[key].distance = GetDistance(locationPoints);
        });
        searchResult.data.data.sort((a, b) => a.distance - b.distance);
        setCategoryBranches(searchResult.data.data);
        setIsLoading(false);
    }

    const handleMerchantBranchClick = (item) => {
        history.push('/merchants/view/' + item.branch_uuid + '/' + ConvertToSlug(item.branch_name));
    }

    const NoBranches = () => {
        return (
            <div className="py-3">
                <Image src={LogoSmile} preview={false} width={60} />
                <div className="font-color-secondary-x5 mt-3" style={{fontSize: '1.25rem'}}>Oops! We still yet to have a merchant partner that offers {selectedCategory[0].categoryName} food.</div>
            </div>
        )
    }

    const MerchantItem = (item) => {
        return (
            <Col>
                <div className="bm-slider-container">
                    {
                        (item.is_free_delivery === 1) &&
                        <div className="bm-badge" style={{top: '16px', left: '16px'}}>FREE DELIVERY</div>
                    }
                    <Card
                        style={{ borderRadius: 12 }}
                        cover={<img alt={item.branch_name} src={item.listing_photo_file_url} />}
                        bordered={false}
                        className="bm-card-merchant"
                        onClick={() => handleMerchantBranchClick(item)}>
                        <div className="bm-slider-meta-container">
                            <h4>{item.branch_name}</h4>
                            <div className="bm-slider-meta-item">{NumFormat(item.distance, 2)}km</div>
                            <div className="bm-slider-meta-item">{GetFoodCategoryLabels(item.food_category_types)}</div>
                            {
                                (item.is_free_delivery === 1) &&
                                <div className="min-delivery-wrapper">
                                    <div className="min-delivery-icon">
                                        <Icon component={Motorcycle} />
                                    </div>
                                    <div className="min-delivery-label">Min Spend P{NumFormat(item.min_free_delivery / 100)}</div>
                                </div>
                            }
                        </div>
                    </Card>
                </div>
            </Col>
        )
    }

    useEffect(() => {
        handleSearch();
        ReactGA.pageview(window.location.pathname);
    }, []);
    
    return (
        <PageLayout loading={isLoading}>
            <SectionWrapper>
                <div className="pe-6 pe-lg-0">
                    <h1 className="section-title mt-4 mb-1">{selectedCategory[0].categoryName}</h1>
                    <h2 className="section-subtitle">{selectedCategory[0].categoryName} food merchants around {serviceArea.service_area_name}.</h2>
                </div>
                {
                    categoryBranches.length > 0 &&
                    <List
                        className="mt-5"
                        dataSource={categoryBranches}
                        renderItem={(item) => MerchantItem(item)}
                        grid={{
                            gutter: [36, 36],
                            xs: 1,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 3,
                            xxl: 4
                        }}
                    />
                }
                {
                    categoryBranches.length === 0 &&
                    <Row className="mt-5">
                        <Col xs={24} md={{span: 20, offset: 2}} lg={{span: 16, offset: 4}}>
                            <Alert
                                className="bm-alert light text-center"
                                description={<NoBranches />}
                                type="secondary"
                            />
                        </Col>
                    </Row>
                }
            </SectionWrapper>
        </PageLayout>
    )
}