import axios from 'axios';

export const UserLogin = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/login', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UserRegister = async(values) => {
    try {
        const userResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users', values);
        if (userResult.data.status === 'success') {
            const customerValues = {
                user_uuid: userResult.data.uuid,
                wallet_balance: 0
            }
            const customerResult = await axios.post(process.env.REACT_APP_API_BASE_URL + '/customers', customerValues);
            return customerResult;
        } else {
            return userResult;
        }
    } catch (error) {
        throw error;
    }
}

export const UserUpdate = async(values, uuid) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/users/' + uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UserVerify = async(values, uuid) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/users/' + uuid + '/verify', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUserDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/users/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ForgotPassword = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/forgot-password', values);
        return result;
    } catch (error) {
        throw error;
    }
}