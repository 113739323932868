export const AUTH_LOGIN = 'AUTH_LOGIN';
export const USER_ADDRESSES = 'USER_ADDRESSES';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_SERVICE_AREAS = 'SET_SERVICE_AREAS';
export const SET_NEAR_AREA = 'SET_NEAR_AREA';
export const SET_AREA_BRANCHES = 'SET_AREA_BRANCHES';
export const SET_AREA_ITEMS = 'SET_AREA_ITEMS';
export const SET_AREA_VOUCHERS = 'SET_AREA_VOUCHERS';
export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';
export const SET_SELECTED_MERCHANT = 'SET_SELECTED_MERCHANT';
export const SET_ACTIVE_BRANCH = 'SET_ACTIVE_BRANCH';
export const ADD_BAG_ITEM = 'ADD_BAG_ITEM';
export const UPDATE_BAG_ITEM = 'UPDATE_BAG_ITEM';
export const REMOVE_BAG_ITEM = 'REMOVE_BAG_ITEM';
export const EMPTY_BAG_ITEMS = 'EMPTY_BAG_ITEMS';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_SELECTED_VOUCHER = 'SET_SELECTED_VOUCHER';
export const SET_SELECTED_VOUCHER_ITEM = 'SET_SELECTED_VOUCHER_ITEM';
export const SET_PENDING_ORDERS = 'SET_PENDING_ORDERS';
export const SET_PAST_ORDERS = 'SET_PAST_ORDERS';