import moment from 'moment';
import { FilterObject } from './Utilities';

export const GenerateRandomKey = (length) => {
    let result = [];
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charsLength = chars.length;
    for (var i = 0; i < length; i++) {
        result.push(chars.charAt(Math.floor(Math.random() * charsLength)));
    }
    return result.join('');
}

export const GetDistance = (values) => {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((values.lat2 - values.lat1) * p)/2 + c(values.lat1 * p) * c(values.lat2 * p) * (1 - c((values.lng2 - values.lng1) * p))/2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export const DisplayDateTime = (time) => {
    return moment(time).local().format('lll');
}

export const NumFormat = (number, decimals, dec_point, thousands_sep) => {
    var n = !isFinite(+number) ? 0 : +number, 
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const GetPriceDisplay = (price, merchantPriceRate) => {
    return Math.ceil((price / 100) * (1 + (merchantPriceRate / 100))).toFixed(2);
}

export const GetPrice = (price, merchantPriceRate) => {
    return Math.ceil(price * (1 + (merchantPriceRate / 100)));
}

export const GetAddToBagFormat = (branchDetails, itemsData, merchantPriceRate, item, itemQuantity, itemVariation, itemAddOnRequired, itemAddOnOptional) => {
    // variation
    const variationData = FilterObject(itemsData.variations, itemVariation, 'variation_uuid');
    let variationPrice = variationData[0]['variation_price'];

    // add on required
    let itemAddOnRequiredAmount = 0;
    let itemAddOnRequiredAmountPosted = 0;
    let itemAddOnRequiredArray = [];
    if (itemAddOnOptional) {
        itemAddOnRequired.map(item => {
            // let itemString = item.split('|');
            const addOnRequiredData = FilterObject(itemsData.add_ons, item, 'add_on_uuid');
            itemAddOnRequiredAmount = itemAddOnRequiredAmount + addOnRequiredData[0].add_on_price;
            itemAddOnRequiredAmountPosted = itemAddOnRequiredAmountPosted + GetPriceDisplay(addOnRequiredData[0].add_on_price, merchantPriceRate) * 100;
            itemAddOnRequiredArray.push({
                'add_on_uuid': item,
                'add_on_price': addOnRequiredData[0].add_on_price,
                'add_on_price_posted': GetPriceDisplay(addOnRequiredData[0].add_on_price, merchantPriceRate) * 100,
                'add_on_name': addOnRequiredData[0].add_on_name
            })
        })
    }
    
    // add on optional
    let itemAddOnOptionalAmount = 0;
    let itemAddOnOptionalAmountPosted = 0;
    let itemAddOnOptionalArray = [];
    if (itemAddOnOptional) {
        itemAddOnOptional.map(item => {
            const addOnOptionalData = FilterObject(itemsData.add_ons, item, 'add_on_uuid');
            itemAddOnOptionalAmount = itemAddOnOptionalAmount + addOnOptionalData[0].add_on_price;
            itemAddOnOptionalAmountPosted = itemAddOnOptionalAmountPosted + GetPriceDisplay(addOnOptionalData[0].add_on_price, merchantPriceRate) * 100;
            itemAddOnOptionalArray.push({
                'add_on_uuid': item,
                'add_on_price': addOnOptionalData[0].add_on_price,
                'add_on_price_posted': GetPriceDisplay(addOnOptionalData[0].add_on_price, merchantPriceRate) * 100,
                'add_on_name': addOnOptionalData[0].add_on_name
            })
        })
    }
    
    const addToBagItem = {
        branch_uuid: branchDetails.branch_uuid,
        branch_name: branchDetails.branch_name,
        key: GenerateRandomKey(16),
        item_uuid: item.item_uuid,
        quantity: itemQuantity,
        variation_uuid: itemVariation,
        add_on_required: itemAddOnRequiredArray,
        add_on_optional: itemAddOnOptionalArray,
        variation_amount: variationPrice,
        variation_amount_posted: GetPriceDisplay(variationPrice, merchantPriceRate) * 100,
        add_on_required_amount_unit: itemAddOnRequiredAmount,
        add_on_required_amount_unit_posted: itemAddOnRequiredAmountPosted,
        add_on_required_amount: itemAddOnRequiredAmount * itemQuantity,
        add_on_required_amount_posted: itemAddOnRequiredAmountPosted * itemQuantity,
        add_on_optional_amount_unit: itemAddOnOptionalAmount,
        add_on_optional_amount_unit_posted: itemAddOnOptionalAmountPosted,
        add_on_optional_amount: itemAddOnOptionalAmount * itemQuantity,
        add_on_optional_amount_posted: itemAddOnOptionalAmountPosted * itemQuantity,
        unit_total_price: variationPrice + itemAddOnRequiredAmount + itemAddOnOptionalAmount,
        unit_total_price_posted: GetPriceDisplay(variationPrice, merchantPriceRate) * 100 + itemAddOnRequiredAmountPosted + itemAddOnOptionalAmountPosted,
        total_price: (variationPrice + itemAddOnRequiredAmount + itemAddOnOptionalAmount) * itemQuantity,
        total_price_posted: (GetPriceDisplay(variationPrice, merchantPriceRate) * 100 + itemAddOnRequiredAmountPosted + itemAddOnOptionalAmountPosted) * itemQuantity,
        item_name: item.item_name,
        item_photo_file_url: item.item_photo_file_url,
        variation_name: variationData[0].variation_name
    }
    return addToBagItem;
}

export const GetDeliveryFee = (selectedBranch, currentLocation, serviceArea) => {
    const distanceValues = {
        lat1: selectedBranch.map_lat,
        lng1: selectedBranch.map_lng,
        lat2: currentLocation.lat,
        lng2: currentLocation.lng
    }
    const distance = GetDistance(distanceValues) * 100;
    const computedDistance = distance - serviceArea.delivery_min_radius;
    if (computedDistance <= 0) {
        return serviceArea.delivery_min_fee;
    } else {
        return serviceArea.delivery_min_fee + ((computedDistance / 100) * serviceArea.delivery_excess_per_km);
    }
}