export const FoodCategoryTypes = [
    {
        "id": 1,
        "code": "F001",
        "categoryName": "American"
    },
    {
        "id": 2,
        "code": "F002",
        "categoryName": "Asian"
    },
    {
        "id": 3,
        "code": "F003",
        "categoryName": "Australian"
    },
    {
        "id": 4,
        "code": "F004",
        "categoryName": "Beverages"
    },
    {
        "id": 5,
        "code": "F005",
        "categoryName": "Burgers"
    },
    {
        "id": 6,
        "code": "F006",
        "categoryName": "Cakes & Bakery"
    },
    {
        "id": 7,
        "code": "F007",
        "categoryName": "Chicken"
    },
    {
        "id": 8,
        "code": "F008",
        "categoryName": "Chinese"
    },
    {
        "id": 9,
        "code": "F009",
        "categoryName": "Coffee & Tea"
    },
    {
        "id": 10,
        "code": "F010",
        "categoryName": "Desserts"
    },
    {
        "id": 11,
        "code": "F011",
        "categoryName": "Fast Food"
    },
    {
        "id": 12,
        "code": "F012",
        "categoryName": "Filipino"
    },
    {
        "id": 13,
        "code": "F013",
        "categoryName": "French"
    },
    {
        "id": 14,
        "code": "F014",
        "categoryName": "German"
    },
    {
        "id": 15,
        "code": "F015",
        "categoryName": "Greek"
    },
    {
        "id": 16,
        "code": "F016",
        "categoryName": "Halal"
    },
    {
        "id": 17,
        "code": "F017",
        "categoryName": "Healthy"
    },
    {
        "id": 18,
        "code": "F018",
        "categoryName": "Indian"
    },
    {
        "id": 19,
        "code": "F019",
        "categoryName": "Indonesian"
    },
    {
        "id": 20,
        "code": "F020",
        "categoryName": "International"
    },
    {
        "id": 21,
        "code": "F021",
        "categoryName": "Italian"
    },
    {
        "id": 22,
        "code": "F022",
        "categoryName": "Japanese"
    },
    {
        "id": 23,
        "code": "F023",
        "categoryName": "Korean"
    },
    {
        "id": 24,
        "code": "F024",
        "categoryName": "Lebanese"
    },
    {
        "id": 25,
        "code": "F025",
        "categoryName": "Malaysian"
    },
    {
        "id": 26,
        "code": "F026",
        "categoryName": "Mediterranean"
    },
    {
        "id": 27,
        "code": "F027",
        "categoryName": "Mexican"
    },
    {
        "id": 28,
        "code": "F028",
        "categoryName": "Middle Eastern"
    },
    {
        "id": 29,
        "code": "F029",
        "categoryName": "Pasta"
    },
    {
        "id": 30,
        "code": "F030",
        "categoryName": "Pizza"
    },
    {
        "id": 31,
        "code": "F031",
        "categoryName": "Sandwiches"
    },
    {
        "id": 32,
        "code": "F032",
        "categoryName": "Seafood"
    },
    {
        "id": 33,
        "code": "F033",
        "categoryName": "Singaporean"
    },
    {
        "id": 34,
        "code": "F034",
        "categoryName": "Snacks"
    },
    {
        "id": 35,
        "code": "F035",
        "categoryName": "South East Asian"
    },
    {
        "id": 36,
        "code": "F036",
        "categoryName": "South American"
    },
    {
        "id": 37,
        "code": "F037",
        "categoryName": "Spanish"
    },
    {
        "id": 38,
        "code": "F038",
        "categoryName": "Sushi"
    },
    {
        "id": 39,
        "code": "F039",
        "categoryName": "Thai"
    },
    {
        "id": 40,
        "code": "F040",
        "categoryName": "Vegetarian"
    },
    {
        "id": 41,
        "code": "F041",
        "categoryName": "Vietnamese"
    },
    {
        "id": 42,
        "code": "F042",
        "categoryName": "South American"
    },
    {
        "id": 43,
        "code": "F043",
        "categoryName": "Others"
    },
]
