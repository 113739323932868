import React from 'react';
import { Drawer, Button, Grid, Input, Form } from 'antd';
import { UserUpdate } from '../../services/Users';
import { Debounce } from '../../functions/Utilities';
import { NotificationManager } from 'react-notifications';

import { useDispatch, useSelector } from 'react-redux';
import { setAuthDetails } from '../../store/Action';

const { useBreakpoint } = Grid;

export default function DrawerRightProfileUpdate({isVisible, setDrawerRightProfileUpdate, setDrawerRightProfileView}) {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.reducer.userDetails);
    const handleSetAuthDetails = (userDetails) => dispatch(setAuthDetails(userDetails));

    const screens = useBreakpoint();
    const [form] = Form.useForm();

    const handleForm = async(values) => {
        const postData = {
            first_name: values.first_name,
            last_name: values.last_name
        }
        const result = await UserUpdate(postData, userDetails.user_uuid);
        if (result.data.status === 'success') {
            handleSetAuthDetails({
                ...userDetails,
                first_name: values.first_name,
                last_name: values.last_name
            })
            Debounce(NotificationManager.success('Your profile details are now updated.', 'Notice', 3000), 3000);
            handleClose();
        } else {
            Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 1000), 1000);
        }
    }

    const handleClose = () => {
        setDrawerRightProfileUpdate(false);
        setDrawerRightProfileView(true);
    }

    return (
        <Drawer destroyOnClose={true} className="bm-drawer" title={'Profile Update'} placement="right" width={screens.md === false ? '100%' : 600} onClose={(e) => handleClose()} visible={isVisible}>
            {
                userDetails &&
                <Form layout="vertical" form={form} onFinish={handleForm}>
                    <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'Please enter your first name.' }]}>
                        <Input placeholder="first name" className="bm-input" value={userDetails.first_name} />
                    </Form.Item>
                    <Form.Item label="Last Name" name="last_name" rules={[{ required: true, message: 'Please enter your last name.' }]}>
                        <Input placeholder="last name" className="bm-input" value={userDetails.last_name} />
                    </Form.Item>
                    <Form.Item label="Email Address" name="email_address">
                        <Input placeholder="email address" className="bm-input" disabled={true} defaultValue={userDetails.email_address} />
                    </Form.Item>
                    <Form.Item label="Mobile Number" name="mobile_number">
                        <Input placeholder="mobile number" className="bm-input" disabled={true} defaultValue={userDetails.mobile_number} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="btn btn-primary mt-4" size="large" htmlType="submit" block>Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Drawer>
    )
}