import axios from 'axios';

export const GetMerchantDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMerchantActiveItems = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/merchants/' + uuid + '/items/all/active');
        return result;
    } catch (error) {
        throw error;
    }
}