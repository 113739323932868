export const FoodCategoryTypesFeatured = [
    {
        "id": "11",
        "value": "F011",
        "label": "Fast Food"
    },
    {
        "id": "4",
        "value": "F004",
        "label": "Beverages"
    },
    {
        "id": "5",
        "value": "F005",
        "label": "Burgers"
    },
    {
        "id": "9",
        "value": "F009",
        "label": "Coffee"
    },
    {
        "id": "6",
        "value": "F006",
        "label": "Cakes"
    },
    {
        "id": "7",
        "value": "F007",
        "label": "Chicken"
    },
    {
        "id": "30",
        "value": "F030",
        "label": "Pizza"
    },
    {
        "id": "29",
        "value": "F029",
        "label": "Pasta"
    },
    {
        "id": "32",
        "value": "F032",
        "label": "Seafood"
    },
    {
        "id": "23",
        "value": "F023",
        "label": "Korean"
    },
    {
        "id": "12",
        "value": "F012",
        "label": "Filipino"
    },
    {
        "id": "999",
        "value": "Z000",
        "label": "View All"
    }
]
