import axios from 'axios';

export const GetVouchers = async(uuid, date) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/vouchers/service-area/' + uuid + '/date/' + date);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMaxUses = async(voucher_uuid, user_uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/vouchers/verify/' + voucher_uuid + '/user/' + user_uuid);
        return result;
    } catch (error) {
        throw error;
    }
}